import React, { useState, useEffect } from 'react';
// import { Player } from 'video-react';
import './sessionchild.css';
import Axios from 'axios';
import AlertMsg from '../../services/AlertMsg';
import SessionChildPages from './SessionChildPages';
import PageIndexing from '../../services/PageIndexing';

export default function SessionChild() {
    // const [sessions, setSession] = useState([]);
    const [errorAlert, setErrorAlert] = useState('')

    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(3)

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    const baseUrl = 'http://lms.rightfullabs.com/apis/';

    useEffect(() => {
        setLoading(true);
        Axios.get(baseUrl + 'sessions/getSessionsData',
            {
                method: 'GET',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
                // console.log(res);
                setPosts(res.data);
                setLoading(false);
                if (res.data == '') {
                    setErrorAlert({ type: "warning", message: "Data not found!" });
                } else {
                    setErrorAlert({ type: "success", message: "Data load successfully!" });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const snackbarClose = () => {
        setErrorAlert(false);
    };

    // useEffect(() => {
    //     const fetchPosts = async () => {
    //         setLoading(true);
    //         const res = await Axios.get(baseUrl + 'sessions/getSessionsData',
    //             {
    //                 method: 'GET',
    //                 headers: {
    //                     "Authorization": `LMS-oauthtoken ${jwt}`,
    //                     "Content-Type": "application/json",
    //                     "Apiuserid": "2020010157",
    //                     "Scope": "LMS.Rightfullabs.READ"
    //                 },
    //             }
    //         );
    //         console.log("khali data");

    //         setPosts(res.data);
    //         setLoading(false);
    //     }
    //     fetchPosts();
    // }, [])
    // console.log(posts);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>
            {
                errorAlert &&
                <AlertMsg type={errorAlert.type} message={errorAlert.message} open={errorAlert} close={snackbarClose} autoclose={3000} />
            }
            <SessionChildPages posts={currentPosts} loading={loading} />
            {/* <Posts posts={currentPosts} loading={loading} /> */}
            <PageIndexing postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} />
        </>
    )
}
