import React, { useState, useEffect } from 'react'
import ProfileChild from './ProfileChild'

function ProfileData() {

    const [myProfile, setMyProfile] = useState([]);
    useEffect(() => {
        var data = JSON.parse(localStorage.getItem('userData'));
        // console.log(data);
        setMyProfile([data]);
    }, [])

    return (
        <div>
            {
                myProfile.map((x) => {
                    return <ProfileChild myProfile={x} />
                })
            }
        </div>
    )
}

export default ProfileData
