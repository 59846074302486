import React, { useState } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import './topnavbar.css';

export default function TopNavBar(props) {
    // const [wrapperStatus, setwrapperStatus] = useState(false);

    // function toggle() {
    //     var wrapper = document.getElementById("wrapper");
    //     if (wrapperStatus) {
    //         wrapper.classList.remove("toggled");
    //         setwrapperStatus(false);
    //     }
    //     else {
    //         wrapper.classList.add("toggled");
    //         setwrapperStatus(true);
    //     }
    // }
    return (
        <div>
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                {/*  style={{ position: 'fixed', width: '100%', zIndex: '1', height: '90px', left:'0' }} */}
                {/* <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>
                <button className="btn btn-warn" id="menu-toggle" data-toggle="collapse" aria-expanded="false" onClick={toggle}>
                    <span className="fa fa-align-justify"></span>
                </button> */}
                {/* <div className="logo-pos">
                    <a className="logo" href="#">LMS</a>
                </div> */}
                <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                    {/*  style={{paddingLeft:'40px'}} */}
                    <div className="input-group">
                        {/* <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="button">
                                <i className="fas fa-search fa-sm"></i>
                            </button>
                        </div>
                        <input className="search_input" type="text" name="" placeholder="Search..." />
                        <a href="#" className="search_icon"><i className="fa fa-search"></i></a> */}
                        <div className="searchbar">
                            <input className="search_input" type="text" name="" placeholder="Search..." />
                            <a href="#" className="search_icon"><i className="fa fa-search"></i></a>
                        </div>
                    </div>
                </form>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-search fa-fw"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>
                    <li className="nav-item dropdown no-arrow mx-1">
                        <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-bell fa-fw" style={{ fontSize: '35px' }}></i>
                            <span className="badge badge-danger badge-counter" style={{ fontSize: '14px' }}>&nbsp;</span>
                        </a>
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header" style={{ backgroundColor: '#ff4500', borderColor: '#ff4500', textTransform: 'uppercase', textAlign: 'center' }}>Notifications</h6>
                            {/* <span className="fa-notify up"></span> */}
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-bell text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-bell text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-bell text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                        </div>
                    </li>
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className="nav-item dropdown no-arrow" key={props.person.id}>
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {props.person.userImage === "http://hrms.rightfullabs.com/apis/upload_profile_img/" ? <img className="mr-3 img-profile rounded-circle" src="http://lms.rightfullabs.com/images/user.png" alt="" height="50" width="50" /> : <img className="mr-3 img-profile rounded-circle" src={props.person.userImage} alt="" height="55" width="55" style={{ borderRadius: '25px' }} />}
                            <span className="mr-2 d-none d-lg-inline text-gray-600 big" style={{ textAlign: 'center', textTransform: 'capitalize' }}>{props.person.name} !
                            <br />
                                <span className="ml-2 d-none d-lg-inline text-gray-600 small" style={{ textAlign: 'center', textTransform: 'capitalize' }}>{props.person.designation}</span></span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <h6 className="dropdown-header" style={{ backgroundColor: '#ff4500', borderColor: '#ff4500', color: 'white' }}>
                                {props.person.userEmail}
                            </h6>
                            {/* <div className="dropdown-divider"></div> */}
                            <NavLink className="ml-0 dropdown-item profile-link" to="/profile" exact={true} activeStyle={{ color: '#ff4500' }}>
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>My Profile
                            </NavLink>
                            <NavLink className="dropdown-item profile-link" to="/logout" exact={true} activeStyle={{ color: '#ff4500' }}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>Logout
                            </NavLink>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
