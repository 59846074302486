import React from 'react';
// import Pagination from '@material-ui/lab/Pagination';

export const PageIndexing = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <>
            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className="page-item">
                            <a onClick={() => paginate(number)} className="page-link" style={{ cursor: 'pointer' }}>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
            {/* <Pagination count={10} color="secondary" /> */}
        </>
    )
}

export default PageIndexing;