import React, { useState, useEffect } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import Axios from 'axios'
import AlertMsg from '../../services/AlertMsg';
import UpdateCourseChild from './UpdateCourseChild'
// import Data from './Data'

function TlCourseCard() {
    const [course, setCourse] = useState([])
    const [errorAlert, setErrorAlert] = useState('');
    const [editcourse, setEditCourse] = useState([])
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];
    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    useEffect(() => {
        //setLoading(true)

        Axios.get(baseUrl + 'AdminPanel/AllCourseData',
            {
                method: 'GET',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
               console.log(res.data[0])
                setCourse(res.data)
               var id= localStorage.setItem('course_id',JSON.stringify( res.data.course_id));
console.log(id)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    function editCourse(id) {
        var course_id = id;
        var data = { course_id: course_id }
        console.log(data)
        Axios.post(baseUrl + 'AdminPanel/CourseData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },

            }
        )
            .then(res => {
            
                setEditCourse(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }
 function deleteCourse(id){

                   var course_id = id;
        var data = { course_id: course_id }
        var id = JSON.stringify(data);
      
        const heads = {
            method: 'POST',
            headers: {
                "Authorization": `LMS-oauthtoken ${jwt}`,
                "Content-Type": "application/json",
                "Apiuserid": "2020010157",
                "Scope": "LMS.Rightfullabs.DELETE"
            },
        }
        Axios.post(baseUrl + "AdminPanel/CourseData", id, heads)
            .then(res => {
                
                 
                if (res.data.code == 200 || res.data.code == 400 || res.data.code == 401) {
                    document.getElementById(course_id).remove();
                    setErrorAlert({ type: "success", message: res.data.message });
                   
                    // setStatus(true);
                }
            })
            .catch(error => {
                // console.log(error) 
                setErrorAlert({ type: "error", message: 'Something went wrong. Please try again!' });
            })
 }
    return (
        <>
            {
                course.map((x,index) => {
                    return (
                        <>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" id={x.course_id} key={x.course_id}>
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between"  >
                                        <h6 className="h4 m-0 font-weight-bold text-secondary">{x.course_title}<br />
                                            <span className="small" style={{ fontSize: '14px' }}><b>Duration</b> : {x.course_duration}</span>
                                        </h6>
                                        {/* <button type="button" className="btn btn-warning start-btn">Continue</button> */}
                                        <div className="row ml-3">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                {/* {data.teamLeader === 1 || data.userId === 127 || data.userId === 209 ? <span className="fa fa-pencil-square-o" style={{ cursor: 'pointer', fontSize: '26px', color: '#ff4500', position: 'relative', right: '15px', top: '2px' }}></span> : null} */}
                                                <span className="fa fa-pencil-square-o" style={{ cursor: 'pointer', fontSize: '26px', color: '#ff4500', position: 'relative', right: '15px', top: '2px' }} onClick={() => { editCourse(x.course_id) }}></span>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                {/* {data.teamLeader === 1 || data.userId === 127 || data.userId === 209 ? <span className="fa fa-trash-o" style={{ cursor: 'pointer', fontSize: '24px', color: '#ff4500', position: 'relative', right: '15px' }} onClick={() => deleteSession(session.session_id)}></span> : null} */}
                                                <span className="fa fa-trash-o" style={{ cursor: 'pointer', fontSize: '24px', color: '#ff4500', position: 'relative', right: '15px' }} 
                                                 data-toggle="modal" data-target={`#myModal${x.course_id}`}
                                                ></span>
                                            </div>
                                            <div className="modal fade" id={`myModal${x.course_id}`}>
                                                                    <div className="modal-dialog modal-sm" style={{ margin: '1.75rem auto' }}>
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h4 className="modal-title">Are you sure?</h4>
                                                                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                            </div>
                                                                            <div className="modal-body">{x.course_title}</div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { deleteCourse(x.course_id) }}>Confirm</button>
                                                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                        </div>
                                    </div>
                                    <div className="card-body text-drak">
                                        {/* <h5 className="card-title">Success card title</h5> */}
                                        <p className="course-desc">{x.course_desc}</p>
                                        <p className="course-img-p"><img className="course-img" src="/assets/images/12.png" alt="" width="85" height="85" /></p>
                                        <div className="clear-both"></div>
                                    </div>
                                    <div class="card-footer bg-transparent border-gray">
                                                <h6 class="h6 mb-3-0 font-weight-bold text-secondary">Courses Details :</h6>
                                                <div className="row">
                                                    <div className="col-md-6 fa-icon"><span className="fa fa-newspaper-o fa-text"></span>{x.total_lessons} Lessons</div>
                                                    <div className="col-md-6 fa-icon"><span className="fa fa-tasks  fa-text" ></span>{x.total_lessons} file</div>
                                                    {/* <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div> */}
                                                </div>
                                            </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }
            {
                editcourse.map(x => {
                    return (<Redirect to={`/teamlead/updateCourse/${x.course_id}`} />)
                })
            }
        </>
    )
}

export default TlCourseCard
