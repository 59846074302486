import React from 'react'

export default function HelpChild() {
    return (
        <>
            {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-3 text-gray-800">Cards</h1>
            </div> */}
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-5">
                    <h1>Help Center!</h1>
                    <h6>Need Answers? Find them here.</h6>
                    <div className="help-img">
                        <img className="img-fluid" src="/assets/images/help.png" alt="" />
                    </div>
                </div>
                <div className="col-lg-5">
                    <p className="help-desc">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </p>
                    <div className="container">
                        <div id="accordion" className="accordion">
                            <div className="card mb-3">
                                <div className="card-header collapsed show" data-toggle="collapse" href="#collapseOne">
                                    <a className="card-title">Item 1</a>
                                </div>
                                <div id="collapseOne" className="card-body collapse show" data-parent="#accordion">
                                    <p>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </p>
                                </div>
                            </div>
                            <div className='br'></div>
                            <div className="card mb-3">
                                <div className="card-header collapsed" data-toggle="collapse" href="#collapseTwo">
                                    <a className="card-title">Item 2</a>
                                </div>
                                <div id="collapseTwo" className="card-body collapse" data-parent="#accordion">
                                    <p>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </p>
                                </div>
                            </div>
                            <div className='br'></div>
                            <div className="card mb-3">
                                <div className="card-header collapsed" data-toggle="collapse" href="#collapseThree">
                                    <a className="card-title">Item 3</a>
                                </div>
                                <div id="collapseThree" className="card-body collapse" data-parent="#accordion">
                                    <p>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </p>
                                </div>
                            </div>
                            <div className='br'></div>
                            <div className="card mb-3">
                                <div className="card-header collapsed" data-toggle="collapse" href="#collapseFour">
                                    <a className="card-title">Item 4</a>
                                </div>
                                <div id="collapseFour" className="card-body collapse" data-parent="#accordion">
                                    <p>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </p>
                                </div>
                            </div>
                            <div className='br'></div>
                            <div className="card mb-3">
                                <div className="card-header collapsed" data-toggle="collapse" href="#collapseFive">
                                    <a className="card-title">Item 5</a>
                                </div>
                                <div id="collapseFive" className="card-body collapse" data-parent="#accordion">
                                    <p>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
