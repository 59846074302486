import React from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCricleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SnackBar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { green, amber, red, blue } from '@material-ui/core/colors';
import { SnackbarContent } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: red[600],
    },
    warning: {
        backgroundColor: amber[700],
    },
    info: {
        backgroundColor: blue[600],
    },
    icon: {
        fontSize: 20,
        marginTop: -2,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
        height: 30,
        width: 30,
        lineHeight: 1
    },
    message: {
        display: 'flex',
        alingItems: 'center',
    },
}));

export default function AlertMsg(props) {
    const classes = useStyles();
    return (
        <>
            <SnackBar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={props.open}
                autoHideDuration={props.autoclose}
                onClose={props.close}>
                <SnackbarContent
                    className={clsx(classes[props.type])}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.message}>
                            <Icon className={clsx(classes.icon, classes.iconVariant)}>
                                {
                                    props.type === 'error'
                                        ? <ErrorIcon />
                                        : (
                                            props.type === 'warning'
                                                ? <WarningIcon />
                                                : (
                                                    props.type === 'info'
                                                        ? <InfoIcon />
                                                        : <CheckCricleIcon />
                                                )
                                        )
                                }
                            </Icon>
                            {props.message}
                        </span>
                    }
                    action={[
                        <IconButton key="close"
                            arial-label="close"
                            color="inherit"
                            onClick={props.close}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </SnackBar>
        </>
    )
}
