import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
//import  'createcoursechild.css'
import Axios from 'axios';
// import AlertMsg from '../../services/AlertMsg';
function CreateCourseChild() {

    const [coursetype, setCourseType] = useState([]);
    // const [errorAlert, setErrorAlert] = useState('');
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];
    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    useEffect(() => {
        //setLoading(true)

        Axios.get(baseUrl + 'courses/getCourseType',
            {
                method: 'GET',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
               
                setCourseType(res.data);
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    function createCourseChild() {
        var course_title = document.getElementById("course_title").value;
        var author = document.getElementById("author").value;
        var course_type = document.getElementById("course_type").value;
        var course_duration = document.getElementById("course_duration").value;
        var course_desc = document.getElementById("course_desc").value;
        var course_prerequisite = document.getElementById("course_prerequisite").value;

        var data = {
            course_title: course_title,
            author: author,
            course_type: course_type,
            course_duration: course_duration,
            course_desc: course_desc,
            course_prerequisite: course_prerequisite
        }
        console.log(data);

        const options = {
            method: 'POST',
            headers: {
                "Authorization": `LMS-oauthtoken ${jwt}`,
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Apiuserid": "2020010157",
                "Scope": "LMS.Rightfullabs.INSERT"
            },
        }
        Axios.post(baseUrl + 'AdminPanel/CourseData', data, options
        )
            .then(res => {
               
                // if (res.data.code == 200 || res.data.code == 400 || res.data.code == 401) {
                //     // alert(res.data.message);
                //     setErrorAlert({ type: "success", message: res.data.message });
                //     setUpload(true);
                // }
            })
            .catch(err => {
                // console.log(err);
                // setErrorAlert({ type: "error", message: 'Something went wrong. Please try again!' });
            })


    }

    return (
        <>
            {/* {
                errorAlert &&
                <AlertMsg type={errorAlert.type} message={errorAlert.message} open={errorAlert} close={snackbarClose} autoclose={3000}/>
            } */}

            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Add New Course</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 col-md-12">
                    <div className="container-fluid" style={{ backgroundColor: 'white', padding: '10px 50px', borderRadius: '25px' }}>
                        <form>
                            <h2 className="course-title" style={{ paddingTop: '25px' }}>Course Information</h2>
                            <div className="row" style={{ paddingTop: '25px' }}>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Course Title</label>
                                        <input type="text" className="form-control" id="course_title" name="course_title" placeholder="Enter Course Title" autofocus="" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Author Name</label>
                                        <input type="text" className="form-control" id="author" name="author" placeholder="Enter Author Name" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Course Type</label>
                                        <select className="form-control" id="course_type" name="course_type">
                                            <option value="">Select Course Type</option>
                                            {
                                                coursetype.map((x, index) => {
                                                    return <option key={index} value={x.course_type_id} id="course_type">{x.course_type_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label>Course Duration</label>
                                    <select className="form-control" id="course_duration" name="course_duration">
                                        <option value="">Select Duration</option>
                                        <option value="1 Month">1 Month</option>
                                        <option value="2 Months">2 Months</option>
                                        <option value="3 Months">3 Months</option>
                                    </select>
                                    {/* <div className="form-group">
                                        <input type="text" className="form-control" name="course_duration" id="course_duration" placeholder="" />
                                    </div> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label>Course Description</label>
                                    <div className="form-group" >
                                        <input type="text" className="form-control" style={{ height: "200px" }} id="course_desc" placeholder="Course Description" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label>Course Presquites</label>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="course_prerequisite" placeholder="Course Presquites" style={{ height: "200px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <NavLink to="/teamlead/createlesson">
                                            <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} onClick={() => { createCourseChild() }}> Create Course</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateCourseChild;
