export default function PostData(type, scope, userData) {
    if (type === 'login') {
        let BaseUrl = 'http://hrms.rightfullabs.com/apis/';

        return new Promise((resolve, reject) => {
            fetch(BaseUrl + type, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    'Apiuserid': '2020010157',
                    "Content-Type": "application/json",
                    'Scope': `HRMS.Rightfullabs.${scope}`
                },
                body: JSON.stringify(userData)
            })
                .then((response) => response.json())
                .then((authUser) => {
                    resolve(authUser);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    // else {
    //     var data = JSON.parse(localStorage.getItem('userData'));
    //     var jwt = data['jwt'];

    //     let BaseUrl = 'http://lms.rightfullabs.com/apis/';

    //     return new Promise((resolve, reject) => {
    //         fetch(BaseUrl + type, {
    //             method: 'POST',
    //             headers: {
    //                 "Accept": "application/json",
    //                 'Apiuserid': '2020010157',
    //                 "Authorization": `LMS-oauthtoken ${jwt}`,
    //                 "Content-Type": "application/json",
    //                 'Scope': `LMS.Rightfullabs.${scope}`,
    //             },
    //             body: JSON.stringify(userData)
    //         })
    //             .then((response) => response.json())
    //             .then((authUser) => {
    //                 resolve(authUser);
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //             });
    //     });
    // }
}
