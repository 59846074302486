import React from 'react'
import FileViewer from 'react-file-viewer';
function TutorialChild(props) {
    var x = props.data;
    console.log(x);
    const file = `http://lms.rightfullabs.com/apis/uploads/courses/${x.file_path}`
    const type = x.file_type;

    console.log(type);
    console.log(type)
    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-1">
                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11" key={x.chapter_id}>
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                        <h4 className=" mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>Chapter {x.chapter_number}</h4>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                        <h1 className="h2 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>{x.chapter_title}</h1>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h6 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>{x.chapter_desc}</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-1">
                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="row">
                        <div className="col-lg-12">
                            <FileViewer
                                filePath={file}
                                fileType={type}
                                width={500}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="d-sm-flex align-items-center justify-content-between mb-1">
                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mt-4">
                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="d-sm-flex align-items-center justify-content-between mt-4">
                        test
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="d-sm-flex align-items-center justify-content-between mt-4">
                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TutorialChild
