import React from 'react';
import { NavLink } from 'react-router-dom';
//import  'createcoursechild.css'
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import CreateChapterChild from '../../components/teamleaderchild/CreateChapterChild';
import Axios from 'axios';

function CreateChapter() {
    return (
        <>
          <div id="wrapper">
                <SideNavBar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <UserData />
                        <div className="container-fluid">
                        <CreateChapterChild/>
                        </div>
                    </div>
                </div>
            </div>     
        </>
    )
}

export default CreateChapter;
