import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import QuizChild from './QuizChild';

export default function QuizData() {
    const [quizData, setQuizData] = useState([]);

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    const heads = {
        method: 'GET',
        headers: {
            "Authorization": `LMS-oauthtoken ${jwt}`,
            "Content-Type": "application/json",
            "Apiuserid": "2020010157",
            "Scope": "LMS.Rightfullabs.READ"
        },
    }

    useEffect(() => {
        Axios.get(baseUrl + 'courses/getQuizData', heads)
            .then(res => {
                // console.log(res);
                setQuizData(res.data);
            })
            .catch(err => {
                // console.log(err);
            })
    }, [])

    return (
        <>
            {
                // quizData.map((x) => {
                    <QuizChild quizData={quizData} />
                // })
            }
        </>
    )
}
