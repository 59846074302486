import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

export default function JwtExpiryToken() {
    const [expiry, setExpiry] = useState(true)
    useEffect(() => {
        checkJwt();
    }, [])

    function checkJwt() {
        var localData = localStorage.getItem('userData')
        if (localData === null) {
            setExpiry(false);
            return (<Redirect to='/login' push={true} />);
        } else {
            var expiryData = JSON.parse(localStorage.getItem('userData'));
            var expired = expiryData['expired'];
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            var day = now.getDate();
            var hour = now.getHours();
            var minute = now.getMinutes();
            var second = now.getSeconds();
            if (month.toString().length == 1) { month = '0' + month; }
            if (day.toString().length == 1) { day = '0' + day; }
            if (hour.toString().length == 1) { hour = '0' + hour; }
            if (minute.toString().length == 1) { minute = '0' + minute; }
            if (second.toString().length == 1) { second = '0' + second; }
            var dateTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
            console.log(dateTime)
            if (expired <= dateTime) {     //'2020-03-13 18:52:12'
                localStorage.removeItem('userData');
                setExpiry(false);
            }
        }
    }
    if (!expiry) {
        return (<Redirect to='/login' push={true} />);
    }

    return (
        <>
        </>
    );
}