import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './sidenavbar.css';

export default function SideNavBar() {
    const [role, setRole] = useState([0])
    useEffect(() => {
        var data = JSON.parse(localStorage.getItem('userData'));
        setRole(data);
        // console.log(data.teamLeader);
    }, []);

    if ( role===[0]) {
        // console.log("test");
    } else {
        return (
            <>
                <nav id="sidebar" className="active" style={{minWidth: '85px'}}>
                    {/* <div className="logo-pos">
                        <a className="logo" href="#">LMS</a>
                    </div> */}
                    <ul className="list-unstyled components mb-5">
                        {/*  style={{ position: 'fixed', width: '110px', top: '70px' }} */}
                        {role.teamLeader === 1 || role.userId === 127 || role.userId === 209 ?
                            <>
                                <div className="logo-pos">
                                    <a className="logo" href="#">LMS</a>
                                </div>
                                <li id="sidebar-li">
                                    <NavLink to="/dashboard" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-signal sidebar-links"></span>Dashboard
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/courses" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-pencil-square-o sidebar-links"></span>Courses
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sessions" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-line-chart sidebar-links"></span>Sessions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/help" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-exclamation sidebar-links"></span>Help
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/teamlead" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-user sidebar-links"></span>Team Leader
                                    </NavLink>
                                </li>
                            </> :
                            <>
                                <div className="logo-pos">
                                    <a className="logo" href="#">LMS</a>
                                </div>
                                <li id="sidebar-li">
                                    <NavLink to="/dashboard" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-signal sidebar-links"></span>Dashboard
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/courses" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-pencil-square-o sidebar-links"></span>Courses
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sessions" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-line-chart sidebar-links"></span>Sessions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/help" exact activeStyle={{ color: '#ff4500' }}>
                                        <span className="fa fa-exclamation sidebar-links"></span>Help
                                    </NavLink>
                                </li>
                            </>
                        }
                    </ul>
                </nav>
            </>
        )
    }
}
