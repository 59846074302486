import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import FileViewer from 'react-file-viewer';
import TutorialChild from './TutorialChild';
//import '/App.css'

export default function SkillsTutorialChild(props) {
    var id = props.id;

    const [lesson, setLesson] = useState([])
    const [tutorial, setTutorial] = useState([])
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';


    useEffect(() => {
        var data = { chapter_id: id }
        console.log(data);
        Axios.post(baseUrl + 'courses/AllChapterDataByID', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
                console.log(res);
                setTutorial(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    return (
        <>
            {
                tutorial.map(x => {
                    return (
                        <>
                            <TutorialChild data={x}/>
                            {/* <div className="row">
                                <div className="col-lg-1">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-1">
                                        {/* <h1 className="h3 mb-0 text-gray-800"></h1>
                                    </div>
                                </div>
                                <div className="col-lg-11" key={x.chapter_id}>
                                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                                        <h4 className=" mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>Chapter {x.chapter_number}</h4>
                                    </div>
                                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                                        <h1 className="h2 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>{x.chapter_title}</h1>
                                    </div>
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h1 className="h6 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>{x.chapter_desc}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-1">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-1">
                                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> 
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <FileViewer
                                        filePath={`http://lms.rightfullabs.com/apis/uploads/courses/${x.file_path}`}
                                        fileType={x.file_type}
                                        width={200}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-1">
                                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> 
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-1">
                                    <div className="d-sm-flex align-items-center justify-content-between mt-4">
                                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> 
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="d-sm-flex align-items-center justify-content-between mt-4">
                                        test
                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mt-4">
                                        {/* <h1 className="h3 mb-0 text-gray-800"></h1> 
                                    </div>
                                </div>
                            </div> */}
                        </>
                    )
                })
            }
        </>
    )
}
