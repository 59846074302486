import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { NavLink, Redirect } from 'react-router-dom';
import Axios from 'axios';
import AlertMsg from '../../services/AlertMsg';

function UpdateCourseChild(props) {
    // var { id } = useParams();
    var id = props.id;
  
    const [coursetype, setCourseType] = useState([])
    const [coursedata, setCourseData] = useState([])
    const [lesson, setLesson] = useState([])
    const [errorAlert, setErrorAlert] = useState('');
    const [update, setUpdate] = useState(false);
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];
    let baseUrl = 'http://lms.rightfullabs.com/apis/';



    useEffect(() => {
        var data = { course_id: id }
        // console.log(data)
        Axios.post(baseUrl + 'AdminPanel/CourseData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
                
                setCourseData(res.data)

                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    
    useEffect(() => {
        Axios.get(baseUrl + 'courses/getCourseType',
            {
                method: 'GET',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
             
                setCourseType(res.data)
                // //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

 
    


    function updateCourseChild() {
        var course_title = document.getElementById("course_title").value;
        var author = document.getElementById("author").value;
        var course_type = document.getElementById("course_type").value;
        var course_duration = document.getElementById("course_duration").value;
        var course_desc = document.getElementById("course_desc").value;
        var course_prerequisite = document.getElementById("course_prerequisite").value;
        var data = {
            course_id: id,
            course_title: course_title,
            author: author,
            course_type: course_type,
            course_duration: course_duration,
            course_desc: course_desc,
            course_prerequisite: course_prerequisite
        }
        console.log(data);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `LMS-oauthtoken ${jwt}`,
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Apiuserid": "2020010157",
                "Scope": "LMS.Rightfullabs.UPDATE"
            },
        }
        Axios.post(baseUrl + 'AdminPanel/CourseData', data, options
        )
            .then(res => {
               
                // if (res.data.code === 200 || res.data.code ===400 || res.data.code === 401) {
                    
                //     setErrorAlert({ type: "success", message: res.data.message });
                //      setUpdate(true);
                // }
                if (res.data.code == 400 || res.data.code == 401) {
                    setErrorAlert({ type: "error", message: res.data.message });
                } else {
                    setErrorAlert({ type: "success", message: res.data.message });
                    setUpdate(true);
                }
            })
            .catch(err => {
                console.log(err);
                setErrorAlert({ type: "error", message: 'Something went wrong. Please try again!' });
            })
    }

    if(update){
        return(<Redirect to="/teamlead" />)
    }

    function allLesson() {
        var course_id = id;
        var data = { course_id: course_id }
        Axios.post(baseUrl + 'AdminPanel/CourseData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
             
                setLesson(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const snackbarClose = () => {
        setErrorAlert(false);
    };

    // if (update) {
    //     return (<Redirect to="/sessions" push={true} />);
    // }

    return (
        <>
            {
                errorAlert &&
                <AlertMsg type={errorAlert.type} message={errorAlert.message} open={errorAlert} close={snackbarClose} autoclose={3000} />
            }
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Update  Course</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 col-md-12">
                    <div className="container-fluid" style={{ backgroundColor: 'white', padding: '10px 50px', borderRadius: '25px' }}>
                        {/* <form> */}
                            <h2 className="course-title" style={{ paddingTop: '25px' }}>Course Information</h2>
                            {
                                coursedata.map(x => {
                                    return (
                                        <>
                                            <div className="row" style={{ paddingTop: '25px' }}>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Course Title</label>
                                                        <input type="text" className="form-control" id="course_title" name="course_title" defaultValue={x.course_title} placeholder="Enter Course Title" autofocus="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Author Name</label>
                                                        <input type="text" className="form-control" id="author" name="author" defaultValue={x.author} placeholder="Enter Author Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Course Type</label>
                                                        <select className="form-control" id="course_type" defaultValue={x.course_type} name="course_type">
                                                            <option value="">Select Course Type</option>
                                                            {
                                                                coursetype.map((type, index) => {
                                                                    return <option key={index} value={type.course_type_id} id="course_type" 
                                                                     >{type.course_type_name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label>Course Duration</label>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="course_duration" id="course_duration" defaultValue={x.course_duration}
                                                            placeholder="Course Duration" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <label>Course Description</label>
                                                    <div className="form-group" >
                                                        <input type="text" className="form-control" style={{ height: "200px" }} id="course_desc" defaultValue={x.course_desc}
                                                            placeholder="Course Description" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <label>Course Presquites</label>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="course_prerequisite" defaultValue={x.course_prerequisite} placeholder="Course Presquites" style={{ height: "200px" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                            <div className="row">
                                <div className="form-group">
                                    {/* <NavLink to="/teamlead"> */}
                                        <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} onClick={() => { updateCourseChild() }}> Update Course</button>
                                    {/* </NavLink> */}
                                </div>
                                <div className="form-group ml-4">
                                    <NavLink to={`/teamlead/lessondata/${id}`}>
                                        <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700", backgroundColor: "#3e64ff", borderColor: "#3e64ff" }} onClick={allLesson}> Edit Lesson</button>
                                    </NavLink>
                                </div>
                            </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateCourseChild;
