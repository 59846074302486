import React from 'react';
// import { Redirect } from 'react-router-dom';
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import DashboardChild from '../../components/dashboardchild/DashboardChild';

export default function Dashboard() {
    // let isAuth = true;
    // if(localStorage.getItem("userData") == null){
    //     isAuth = false;
    //     // alert("aaya hu yaha 1");
    // }
    // if (isAuth == false) {
    //     // alert("aaya hu yaha 2");
    //     return (<Redirect to="/login" />)
    // } else {
        return (
            <>
                <div id="wrapper">
                    <SideNavBar />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <UserData />
                            <div className="container-fluid">
                                {/*  style={{position: 'relative', top: '100px'}} */}
                                <DashboardChild />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    // }
}
