import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import './createsessionchild.css';
import AlertMsg from '../../services/AlertMsg';

export default function CreateSessionChild() {
    const [upload, setUpload] = useState(false);
    const [errorAlert, setErrorAlert] = useState('');

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    function newSession() {
        var session_title = document.getElementById("session_title").value;
        var title = document.getElementById("title").value;
        var present_by = document.getElementById("present_by").value;
        var host_by = document.getElementById("host_by").value;
        var date = document.getElementById("date").value;
        var time = document.getElementById("time").value;
        var duration = document.getElementById("duration").value;
        var description = document.getElementById("description").value;
        var file_name = document.getElementById("file_path").files[0] ? document.getElementById("file_path").files[0].name : '';
        // if(!file_name) {
        //     setErrorAlert({ type: "error", message: 'File must be selected!' });
        // }
        // debugger;
        var data = {
            session_title: session_title,
            title: title,
            present_by: present_by,
            host_by: host_by,
            date: date,
            time: time,
            duration: duration,
            description: description,
            file_path: file_name
        }
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `LMS-oauthtoken ${jwt}`,
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Apiuserid": "2020010157",
                "Scope": "LMS.Rightfullabs.INSERT"
            },
        }
        Axios.post(baseUrl + 'sessions/getSessionsData', data, options
        )
            .then(res => {
                console.log(res);
                if (res.data.code == 400 || res.data.code == 401) {
                    setErrorAlert({ type: "error", message: res.data.message });
                } else {
                    setErrorAlert({ type: "success", message: res.data.message });
                    setUpload(true);
                }
            })
            .catch(err => {
                // console.log(err);
                setErrorAlert({ type: "error", message: 'Something went wrong. Please try again!' });
            })
    }

    const snackbarClose = () => {
        setErrorAlert(false);
    };

    if (upload) {
        return (<Redirect to="/sessions" push={true} />);
    }

    if (data.teamLeader === 1 || data.userId === 127 || data.userId === 209) {
        return (
            <>
                {
                    errorAlert &&
                    <AlertMsg type={errorAlert.type} message={errorAlert.message} open={errorAlert} close={snackbarClose} autoclose={3000} />
                }
                <div className="row">
                    <div className="col-lg-1">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-3 text-gray-800"></h1>
                        </div>
                    </div>
                    <div className="col-lg-11">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Create Sessions</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 col-md-12">
                        <div className="container-fluid" style={{ backgroundColor: 'white', padding: '10px 50px', borderRadius: '25px' }}>
                            <form>
                                <h2 className="course-title" style={{ paddingTop: '25px' }}>Session Information</h2>
                                <div className="row" style={{ paddingTop: '25px' }}>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Session Title</label>
                                            <input type="text" className="form-control" id="session_title" name="session_title" placeholder="Enter Session Title" autofocus="" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Session Sub-Title</label>
                                            <input type="text" className="form-control" id="title" name="title" placeholder="Enter Session Sub-Title" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Present By Person</label>
                                            <input type="text" className="form-control" id="present_by" name="present_by" placeholder="Enter Present By Person Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Host By Person</label>
                                            <input type="text" className="form-control" id="host_by" name="host_by" placeholder="Enter Host By Person Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Date</label>
                                                    <input type="date" className="form-control" id="date" name="date" placeholder="Enter Author" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Time</label>
                                                    <input type="time" className="form-control" id="time" name="time" placeholder="Enter Author" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Duration</label>
                                                    <select className="form-control" id="duration" name="duration">
                                                        <option value="">Select Duration</option>
                                                        <option value="1 Month">1 Month</option>
                                                        <option value="2 Months">2 Months</option>
                                                        <option value="3 Months">3 Months</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea rows="8" type="text" className="form-control" id="description" name="description" placeholder="Type Description Here...." />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Add File</label>
                                            <div className="upload-btn-wrapper upload-file">
                                                <button className="btn btn-primary btn-upload">Upload File</button>
                                                <center><small>Video, PPT, DOCS &amp; PDF Files</small></center>
                                                <input type="file" name="file_path" id="file_path" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="button" value="Create New Session" className="btn btn-warning" onClick={newSession} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (<Redirect to={'/sessions'} />);
    }
}
