import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import AlertMsg from '../../services/AlertMsg';
import CoursesCardChild from './CoursesCardChild';

export default function CoursesChild() {
    const [courses, setCourses] = useState([])
    const [errorAlert, setErrorAlert] = useState('')

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';
    const heads = {
        method: 'GET',
        headers: {
            "Authorization": `LMS-oauthtoken ${jwt}`,
            "Content-Type": "application/json",
            "Apiuserid": "2020010157",
            "Scope": "LMS.Rightfullabs.READ"
        },
    }

    useEffect(() => {
        techanical();
    }, [])

    function techanical() {
        Axios.get(baseUrl + 'courses/techCourseData', heads)
            .then(res => {
             console.log(res)
                setCourses(res.data);
                if (res.data == '') {
                    setErrorAlert({ type: "warning", message: "Data not found!" });
                } else {
                    setErrorAlert({ type: "success", message: "Technical skill data load successfully!" });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    function soft() {
        Axios.get(baseUrl + 'courses/softCourseData', heads)
            .then(res => {
           
                setCourses(res.data);
                if (res.data == '') {
                    setErrorAlert({ type: "warning", message: "Data not found!" });
                } else {
                    setErrorAlert({ type: "success", message: "Soft skill data load successfully!" });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    function optional() {
        Axios.get(baseUrl + 'courses/optCourseData', heads)
            .then(res => {
               
                setCourses(res.data);
                if (res.data == '') {
                    setErrorAlert({ type: "warning", message: "Data not found!" });
                } else {
                    setErrorAlert({ type: "success", message: "Optional skill data load successfully!" });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const snackbarClose = () => {
        setErrorAlert(false);
    };

    return (
        <>
            {
                errorAlert &&
                <AlertMsg type={errorAlert.type} message={errorAlert.message} open={errorAlert} close={snackbarClose} autoclose={3000} />
            }
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Courses</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <ul className="nav nav-pills nav-justified mb-3 mr-5" id="pills-tab" role="tablist">
                        <li className="nav-item" style={{ width: '50%', margin: '0px -25px' }}>
                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" style={{ margin: '0 45px 0 25px' }} onClick={techanical}>Technical Skill Courses</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" style={{ margin: '0 50px 0 22px' }} onClick={soft}>Soft Skill Courses</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false" style={{ margin: '0 80px 0 -10px' }} onClick={optional}>Optional Skill Courses</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11 mt-4">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="row">
                                {/* <div className="col-xl-1 col-lg-1"></div> */}
                                <div className="col-xl-11 col-lg-11">
                                    <div className="row">
                                        {courses.map((courses) => {
                                            return(
                                                <>
                                                <CoursesCardChild courses={courses}/>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="row">
                                {/* <div className="col-xl-1 col-lg-1"></div> */}
                                <div className="col-xl-11 col-lg-11">
                                    <div className="row">
                                        {courses.map(courses => (
                                            <CoursesCardChild courses={courses}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="row">
                                {/* <div className="col-xl-1 col-lg-1"></div> */}
                                <div className="col-xl-11 col-lg-11">
                                    <div className="row">
                                        {courses.map(courses => (
                                            <CoursesCardChild courses={courses}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
