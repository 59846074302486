import React from 'react';
import { NavLink } from 'react-router-dom';
import TlCourseCard from './TlCourseCard';

export default function TeamLeaderChild() {

    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Team Leader</h1>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>
                            <NavLink to="/teamlead/createcourse/">
                                {/* {data.teamLeader === 1 || data.userId === 127 || data.userId === 209 ? <button type="button" className="btn btn-warning session-btn">Create Session</button> : null} */}
                                <button type="button" className="btn btn-warning session-btn">Create New Course</button>
                            </NavLink>
                        </h1>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-xl-1 col-lg-1"></div>
                <div className="col-xl-10 col-lg-10">
                    <div className="row">
                        <TlCourseCard />
                    </div>
                </div>
            </div>
        </>
    )
}
