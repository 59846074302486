import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { NavLink, Redirect } from 'react-router-dom';
import Axios from 'axios';

function UpdateLessonChild(props) {
    var id = props.id;
 console.log(id)
    const [course, setCourse] = useState([])
    const [lesson, setLesson] = useState([])
    const [update, setUpdate] = useState(false);

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    // useEffect(() => {
    //     data={lesson_id:id}
    //     Axios.post(baseUrl + 'AdminPanel/LessonData',data,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 "Authorization": `LMS-oauthtoken ${jwt}`,
    //                 "Content-Type": "application/json",
    //                 "Apiuserid": "2020010157",
    //                 "Scope": "LMS.Rightfullabs.READ"
    //             },
    //         }
    //     )
    //         .then(res => {
               
    //             setCourse(res.data)
    //             //     setPosts(res.data);
    //             // setLoading(false);
    //             //     if (res.data == '') {
    //             //         setErrorAlert({ type: "warning", message: "Data not found!" });
    //             //     } else {
    //             //         setErrorAlert({ type: "success", message: "Data load successfully!" });
    //             //     }
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }, [])

    useEffect(() => {
        var data = { lesson_id: id }
        Axios.post(baseUrl + 'AdminPanel/LessonData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
                console.log(res);
                setLesson(res.data)
                //     setPosts(res.data);
                // setLoading(false);
                //     if (res.data == '') {
                //         setErrorAlert({ type: "warning", message: "Data not found!" });
                //     } else {
                //         setErrorAlert({ type: "success", message: "Data load successfully!" });
                //     }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    function updateLesson() {
        var lesson_id = document.getElementById("lesson_id").value;
        var lesson_number = document.getElementById("lesson_number").value;
        var lesson_title = document.getElementById("lesson_title").value;
        var lesson_desc = document.getElementById("lesson_desc").value;
        var data = {
            lesson_id: lesson_id,
            lesson_number: lesson_number,
            lesson_title: lesson_title,
            lesson_desc: lesson_desc
        }

        Axios.post(baseUrl + 'AdminPanel/LessonData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.UPDATE"
                },
            }
        )
            .then(res => {
                console.log(res);
                setUpdate(true);
                //     setPosts(res.data);
                // setLoading(false);
                //     if (res.data == '') {
                //         setErrorAlert({ type: "warning", message: "Data not found!" });
                //     } else {
                //         setErrorAlert({ type: "success", message: "Data load successfully!" });
                //     }
            })
            .catch(err => {
                console.log(err);
            })
    }

    if (update) {
        return (<Redirect to={`/teamlead/lessons/${id}`} />)
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Lesson :&nbsp;
                            {
                                lesson.map((x) => {
                                    return (
                                        <>
                                            {x.lesson_title}
                                            <input type="hidden" id="lesson_id" value={`${x.lesson_id}`} />
                                        </>
                                    )
                                })
                            }
                        </h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 col-md-12">
                    <div className="container-fluid" style={{ backgroundColor: 'white', padding: '10px 50px', borderRadius: '25px' }}>
                        <form>
                            <h2 className="course-title" style={{ paddingTop: '25px' }}>Lesson Information</h2>
                            {
                                lesson.map(x => {
                                    return (
                                        <>
                                            <div className="row" style={{ paddingTop: '25px' }}>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label>Lesson Number</label>
                                                        <input type="hidden" className="form-control" id="lesson_id" value={x.lesson_id} />
                                                        <input type="number" className="form-control" id="lesson_number" name="lesson_number" defaultValue={x.lesson_number} placeholder=" Lesson Number" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Lesson Title</label>
                                                        <input type="text" className="form-control" id="lesson_title" name="lesson_title" defaultValue={x.lesson_title} placeholder=" Lesson Title" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <label>Course Description</label>
                                                    <div className="form-group" >
                                                        <input type="textarea" className="form-control" style={{ height: "200px" }} id="lesson_desc" defaultValue={x.lesson_desc} placeholder="Lesson Description" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <NavLink to={`/teamlead/lessondata/${id}`}>
                                            <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} onClick={updateLesson}>Update Lesson</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateLessonChild;