
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import './profilechild.css'
import CourseTab from './CourseTab';

export default function ProfileChild(props) {

   

    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>My Profile</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-3">
                    <div className="card profile-card-4">
                        <div className="card-img-block"></div>
                        <div className="card-body pt-5">
                            {/* <img src="https://randomuser.me/api/portraits/men/17.jpg" alt="profile-image" className="profile" /> */}
                            {
                                props.myProfile.userImage === "http://hrms.rightfullabs.com/apis/upload_profile_img/" ?
                                    <img src="/assets/images/user.png" alt="" className="profile" /> :
                                    <img src={props.myProfile.userImage} alt={props.myProfile.userImage} className="profile" />
                            }
                            <p className="card-title text-center" style={{ textTransform: 'capitalize' }}>{props.myProfile.name}</p>
                            <p className="card-title text-center1" style={{ textTransform: 'capitalize' }}>{props.myProfile.designation}</p>
                            <p className="card-title text-center">Contact Information</p>
                            <p className="card-title text-center1">{props.myProfile.userEmail}</p>
                        </div>
                    </div>
                </div>
          


                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-7">
                    <ul class="nav nav-pills nav-justified mb-3 mr-5" id="pills-tab" role="tablist">
                        <li class="nav-item" style={{ width: '50%', margin: '0px -25px' }}>
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" style={{ margin: '0 45px 0 25px' }} >Courses</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" style={{ margin: '0 50px 0 22px' }}>Skills</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false" style={{ margin: '0 80px 0 -10px' }} >Certificate</a>
                        </li>
                    </ul>
                    <div className="col-lg-10 mt-4">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div class="row">
                                <CourseTab/>
                       
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div class="row ">
                                {/* <div className="col-xl-1 col-lg-1"></div> */}
                                <div class="card shadow mb-4" style={{width:"600px"}}>
                            <div class="card-body text-drak" >
                                {/* <h5 class="card-title">Success card title</h5> */}
                                <div className="row">
                                <div className="col-lg-4">
                                    <img src="/assets/images/user.png" height="70px" />
                                </div>
                                <div className="col-lg-8">
                                <h5>Headding</h5>
                                <p>paragraph</p>
                                </div>
                                </div>
                            </div>
                            </div>
                               
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div class="row">
                                {/* <div className="col-xl-1 col-lg-1"></div> */}
                                <div class="card shadow mb-4" style={{width:"600px"}}>
                            <div class="card-body text-drak" >
                                {/* <h5 class="card-title">Success card title</h5> */}
                                <div className="row">
                                <div className="col-lg-4">
                                    <img src="/assets/images/images.jpg" height="80px" />
                                </div>
                                <div className="col-lg-8">
                                <h5>Headding</h5>
                                <p>paragraph</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                </div>
            </div>
        </>
    )
}
