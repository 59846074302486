import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

function CreateChapterChild() {
    const [addbutton, setAddButon] = useState(true)
    const [lesson, setLesson] = useState([])
    const [upload, setUpload] = useState(false);
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];
    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    useEffect(() => {
        Axios.get(baseUrl + 'AdminPanel/LessonData',
            {
                method: 'GET',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
               console.log(res)
                setLesson(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    function addFile() {
        setAddButon(false)
    }

    function addChapter() {
        var last_id = document.getElementById("lesson_id").value;
        var chapter_number = document.getElementById("chapter_number").value;
        var chapter_title = document.getElementById("chapter_title").value;
        var file = document.querySelector('input[type="file"]').files[0];
        // console.log(file)
        var formData = new FormData();
        formData.append("last_id", last_id);
        formData.append("chapter_number", chapter_number);
        formData.append("chapter_title", chapter_title);
        formData.append("file", file);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `LMS-oauthtoken ${jwt}`,
                // "Accept": "application/json",
                "Content-Type": "multipart/form-data",
                "Apiuserid": "2020010157",
                "Scope": "LMS.Rightfullabs.INSERT"
            },
        }
        Axios.post(baseUrl + 'AdminPanel/ChapterData', formData, options)
            .then(res => {
               
                setUpload(true);
            })
            .catch(err => {
                console.log(err);
            })
    }

    if (upload) {
        return (<Redirect to={`/teamlead`} push={true} />);
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h3 className="h3 mb-3 text-gray" style={{ fontWeight: '900', fontSize: "30px" }}>Lesson :&nbsp;
                            {
                                lesson.map((x) => {
                                    return (
                                        <>
                                            {x.lesson_title}
                                            <input type="hidden" id="lesson_id" value={`${x.lesson_id}`} />
                                        </>
                                    )
                                })
                            }
                        </h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 col-md-12">
                    <div className="container-fluid" style={{ backgroundColor: 'white', padding: '10px 50px', borderRadius: '25px' }}>
                        <h2 className="course-title" style={{ paddingTop: '25px' }}>Chapter Information</h2>
                        <div className="row" style={{ paddingTop: '25px' }}>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label>Chapter Number</label>
                                    <input type="text" className="form-control" id="chapter_number" name="chapter_number" placeholder=" Chapter Number" autofocus="" />
                                </div>
                                <div className="form-group">
                                    <label>Chapter Title</label>
                                    <input type="text" className="form-control" id="chapter_title" name="chapter_title" placeholder=" Chapter Title" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label>Add File</label>
                                    <div className="upload-btn-wrapper upload-file">
                                        <button className="btn btn-primary btn-upload">Upload File</button>
                                        <center><small>Video, PPT, DOCS &amp; PDF Files</small></center>
                                        <input type="file" name="file_path" id="file_path" onChange={addFile} />
                                        {/* <input type="hidden" name="file_path_old" id="file_path_old" value="" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    {
                                        addbutton ? <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} disabled onClick={addChapter}> Add Chapter</button> :

                                            <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} onClick={addChapter}> Add Chapter</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateChapterChild;
