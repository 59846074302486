import React from 'react';
import { useParams } from "react-router-dom";
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import CreateLessonChild from '../../components/teamleaderchild/CreateLessonChild';

function CreateLesson() {
    
    return (
        <div>
           <div id="wrapper">
                <SideNavBar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <UserData />
                        <div className="container-fluid">
                            <CreateLessonChild/>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    )
}

export default CreateLesson;
