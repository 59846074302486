import React, { useState, useEffect } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import Axios from 'axios';
// import ChapterChild from '../courseschild/ChapterChild';
// import AllChapterData from './AllChapterData';

function ChapterDataChild(props) {
    var id = props.id;

    const [chapters, setChapters] = useState([]);
    const [editchapter, setEditChapter] = useState([]);
    const [lesson, setLesson] = useState([])

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';


    useEffect(() => {
        var data={lesson_id:id}

        Axios.post(baseUrl + 'AdminPanel/LessonData',data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
          
                setLesson(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }, [])


    useEffect(() => {
        var data = { lesson_id: id }

        Axios.post(baseUrl + 'AdminPanel/AllChapterData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
               
                setChapters(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    function editChapter(id) {
        var chapter_id = id;
        var data = { chapter_id: chapter_id }
        Axios.post(baseUrl + 'AdminPanel/ChapterData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },

            }
        )
            .then(res => {
              
                setEditChapter(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Lesson
                        :&nbsp;
                             {
                                lesson.map((x) => {
                                    return (
                                        <>
                                            {x.lesson_title}
                                            <input type="hidden" id="lesson_id" value={`${x.lesson_id}`} />
                                        </>
                                    )
                                })
                            } 
                        
                         </h1>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <NavLink to="/teamlead/">
                            <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }}>Update Done</button>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-1 col-lg-1"></div>
                <div className="col-xl-10 col-lg-10">
                    <div className="row">
                        {
                            chapters.map(x => {
                                return (
                                    <>
                                        <div className="col-lg-4 mt-4 col-md-4 col-sm-6 col-xs-12">
                                            <div className="card shadow mb-4">
                                                <div className="card-header">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <h6>Chapter {x.chapter_number}</h6>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                                </div>
                                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                                    {/* {data.teamLeader === 1 || data.userId === 127 || data.userId === 209 ? <span className="fa fa-pencil-square-o" style={{ cursor: 'pointer', fontSize: '26px', color: '#ff4500', position: 'relative', right: '15px', top: '2px' }}></span> : null} */}
                                                                    <span className="fa fa-pencil-square-o" style={{ cursor: 'pointer', fontSize: '26px', color: '#ff4500', position: 'relative', right: '15px', top: '2px' }} onClick={() => { editChapter(x.chapter_id) }}></span>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                                    {/* {data.teamLeader === 1 || data.userId === 127 || data.userId === 209 ? <span className="fa fa-trash-o" style={{ cursor: 'pointer', fontSize: '24px', color: '#ff4500', position: 'relative', right: '15px' }} onClick={() => deleteSession(session.session_id)}></span> : null} */}
                                                                    <span className="fa fa-trash-o" style={{ cursor: 'pointer', fontSize: '24px', color: '#ff4500', position: 'relative', right: '15px' }}></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body text-drak">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <img src="/assets/images/play.png" />
                                                        </div>
                                                        <div className="col-lg-5 ml-3">
                                                            <p className="" style={{ fontWeight: "700", fontSize: "20px" }}>{x.chapter_title}</p>
                                                            {/* <div className="row">
                                                    <div className="col-md-6 fa-icon" style={{fontSize:"8px",fontWeight:"700"}}><span className="fa fa-newspaper-o"></span> video</div>
                                                    <div className="col-md-6 fa-icon" style={{fontSize:"8px",fontWeight:"700"}}><span className="fa fa-youtube-play"></span> Videos</div>
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {
                editchapter.map(x => {
                    return (<Redirect to={`/teamlead/updateChapter/${x.chapter_id}`} />)
                })
            }
        {/* </div> */}
        </>
    )
}

export default ChapterDataChild;