import React from 'react';
import '../sessions/sessions.css';
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import SessionsChild from '../../components/sessionChild/SessionChild';

export default function Sessions() {
    return (
        <>
            <div id= "wrapper">
                <SideNavBar/>
                <div id= "contentWrapper" className="d-flex flex-column">
                    <div id= "content">
                        <UserData/>
                        <div className="container-fluid">
                            <SessionsChild/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
