import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { NavLink, Redirect } from 'react-router-dom';
import Axios from 'axios'
// import LessonCard from '../courseschild/LessonCard';
// import AddChapter from '../../pages/teamleader/AddChapter';

function LessonDataChild(props) {
    var id = props.id;
    console.log(id)
    const [course, setCourse] = useState([])
    const [lesson, setLesson] = useState([])
    const [addchapter, setAddChapter] = useState([])

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    useEffect(() => {
        var data={course_id:id}
            Axios.post(baseUrl + 'AdminPanel/CourseData',data,
                {
                    method: 'Post',
                    headers: {
                        "Authorization": `LMS-oauthtoken ${jwt}`,
                        "Content-Type": "application/json",
                        "Apiuserid": "2020010157",
                        "Scope": "LMS.Rightfullabs.READ"
                    },
                }
            )
                .then(res => {
                  
                    setCourse(res.data)
                })
                .catch(err => {
                    console.log(err);
                })
        }, [])

    useEffect(() => {
        var data = { course_id: id }

        Axios.post(baseUrl + 'AdminPanel/AllLessonData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
              
                setLesson(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    function addChapter(id) {
        var lesson_number = id;
        var data = { lesson_number: lesson_number }
        Axios.post(baseUrl + 'AdminPanel/LessonData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },

            }
        )
            .then(res => {
               
                setAddChapter(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Course:&nbsp;
                           
                        {
                                course.map((x) => {
                                    return (
                                        <>
                                            {x.course_title}
                                            <input type="hidden" id="course_id" value={`${x.course_id}`} />
                                        </>
                                    )
                                })
                            }
                        
                         </h1>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>
                                    <NavLink to={`/teamlead/addlesson/${id}`}>
                                        <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700", backgroundColor: "#3e64ff", borderColor: "#3e64ff" }}> Add Lesson</button>
                                    </NavLink>
                                </h1>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>
                                    <NavLink to="/teamlead/">
                                        <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }}> Update Done</button>
                                    </NavLink>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-1 col-lg-1"></div>
                <div className="col-xl-10 col-lg-10">
                    <div className="row">
                        {
                            lesson.map(x => {
                                return (
                                    <>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="card shadow mb-4" key={x.lesson_number}>
                                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                    <h6 className="h4 m-0 font-weight-bold text-secondary"><span className="small" style={{ fontSize: '20px' }} style={{ fontSize: '15px',fontWeight:"bold" }}>Lesson {x.lesson_number}</span><br />{x.lesson_title}

                                                    </h6>
                                                    {/* <div className="row mb-4 ">
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <span className="fa fa-pencil-square-o" style={{ cursor: 'pointer', fontSize: '26px', color: '#ff4500', position: 'relative', right: '15px', top: '2px' }}></span>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <span className="fa fa-trash-o" style={{ cursor: 'pointer', fontSize: '24px', color: '#ff4500', position: 'relative', right: '15px' }}></span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="card-body text-drak">
                                                    <p className="course-desc">{x.lesson_desc}</p>
                                                    <p className="course-img-p"><img className="course-img" src="/assets/images/12.png" alt="" width="85" height="85" /></p>
                                                    <div className="clear-both"></div>
                                                </div>
                                                {/* <div className="card-body text-drak">
                                                    <div className="row">
                                                        <div className="col-md-6 fa-icon mt-3" style={{ fontSize: '17px',fontWeight:"500", paddingRight: '0' }}>Lesson Details:</div>
                                                        <div className="col-md-3 fa-icon" style={{ fontSize: '16px', paddingRight: '0',marginBottom:"30px" }}><span className="fa fa-youtube-play fa-text"> 30 Videos</span></div>
                                                        <div className="col-md-3 fa-icon" style={{ fontSize: '16px', paddingRight: '0' }}><span className="fa fa-tasks fa-text"></span>10 Lessons</div>
                                                    </div>
                                                </div> */}
                                                <div className="card-footer bg-transparent border-gray">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                            <NavLink to={`/teamlead/updateLesson/${x.lesson_id}`}>
                                                                <button className="text-center bg-primary text-center ml-3" style={{ border: "none", borderRadius: "18px", fontSize: "20px", fontWeight: "bold", paddingLeft: "12px" }}>
                                                                    <div className="fa fa-pencil-square-o text-white"></div>
                                                                </button><br />
                                                                <span className="small" style={{ fontSize: '12px',fontWeight:"bold" }}>Edit Lesson</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                            <NavLink to={`/teamlead/addchapter/${x.lesson_id}`}>
                                                                <button className="text-center bg-primary text-center ml-3 " style={{ border: "none", borderRadius: "18px", fontSize: "20px", fontWeight: "bold", paddingLeft: "12px",paddingRight: "12px" }}onClick={() => { addChapter(x.lesson_number) }}>
                                                                    <div className="fa fa-plus text-white"></div>
                                                                </button><br />
                                                                <span className="small" style={{ fontSize: '12px',fontWeight:"bold" }}>Add Chapter</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                            <NavLink to={`/teamlead/chapterdata/${x.lesson_id}`}>
                                                                <button className="text-center bg-primary text-center ml-3" style={{ border: "none", borderRadius: "20px", paddingRight: "12px",fontSize: "20px", fontWeight: "bold", paddingLeft: "12px" }} >
                                                                    <div className="fa fa-newspaper-o text-white"></div>
                                                                </button><br />
                                                                <span className="small" style={{ fontSize: '12px',fontWeight:"bold" }}>Edit Chapter</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {
                addchapter.map(x => {
                    return (<Redirect to={`/teamlead/addChapter/`} />)
                }
                )
            }
        </>
    )
}

export default LessonDataChild;