import React, { useState, useEffect } from 'react';
import Axios from 'axios';

function CourseTab() {

    const[profiledata,setProfileData]=useState([])
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    const baseUrl = 'http://lms.rightfullabs.com/apis/';

    useEffect(() => {
       
        Axios.get(baseUrl + 'profile/ProfileData',
            {
                method: 'GET',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
                console.log(res);
                setProfileData(res.data)
                // setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <div>
                {
                    profiledata.map(x =>{
                        return(
                            <>
                            <div class="card shadow mb-4" style={{width:"600px"}}>
                            <div class="card-body" >
                                {/* <h5 class="card-title">Success card title</h5> */}
                                <div className="row" key={x.course_id}>
                                <div className="col-lg-8 col-md-8 col-sm-6">
                        <div style={{fontSize:"25px",fontWeight:"700"}}>{x.course_title}</div>
                                 {/* <div className="row mt-4 ml-2">
                                 <h6 className="h6 m-0 font-weight-bold text-secondary">Courses Details :</h6>
                                    <div className="row">
                                        <div className="col-md-4 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 10 Lessons</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 30 Videos</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div>
                                    </div>
                                 </div> */}

                                <p className="mt-4">{x.course_desc}</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-4 ml-2">
                               
                                <img src="/assets/images/user.png" height="90px" />
                                </div>
                                </div>
                            </div>
                            </div> 
                            </>
                        )
                    })
                }    
        </div>
    )
}

export default CourseTab
