import React from 'react';
import './dashboardchild.css'

export default function DashboardChild() {
    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Dashboard</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-xl-3 col-lg-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-secondary">Statistics</h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-pie pt-4 pb-2">
                                <canvas id="myPieChart"></canvas>
                            </div>
                            <div className="mt-4 text-center small">
                                <span className="mr-2">
                                    <i className="fas fa-circle text-warning"></i> 25 % Completed
                                </span>
                                <span className="mr-2">
                                    <i className="fas fa-circle text-warn"></i> 25 % Progress
                                </span>
                                <span className="mr-2">
                                    <i className="fas fa-circle text-default"></i> 50 % to Begin
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-secondary">Skills</h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="skill-img-icon">
                                        <img className="responsive" src="/assets/images/06.png" alt="" /></div>
                                    <div className="skill-img-icon-text">Communication</div>
                                </div>
                                <div className="col-md-2">
                                    <div className="skill-img-icon">
                                        <img className="responsive" src="/assets/images/07.png" alt="" /></div>
                                    <div className="skill-img-icon-text">Leadership</div>
                                </div>
                                <div className="col-md-2">
                                    <div className="skill-img-icon"><img className="responsive" src="/assets/images/08.png"
                                        alt="" /></div>
                                    <div className="skill-img-icon-text">PPT</div>
                                </div>
                                <div className="col-md-2">
                                    <div className="skill-img-icon"><img className="responsive" src="/assets/images/09.png"
                                        alt="" /></div>
                                    <div className="skill-img-icon-text">Excel</div>
                                </div>
                                <div className="col-md-2">
                                    <div className="skill-img-icon"><img className="responsive" src="/assets/images/10.png"
                                        alt="" /></div>
                                    <div className="skill-img-icon-text">HTML 5</div>
                                </div>
                                <div className="col-md-2">
                                    <a href="">
                                        <div className="skill-img-icon">
                                            <span className="fa fa-plus more-plus"></span>
                                        </div>
                                        <div className="more-tab">More</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-secondary">Certificate</h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <img className="responsive" src="/assets/images/11.png" alt="" />
                                </div>
                                <div className="col-md-8">
                                    <div className="certi-level">Level</div>
                                    <div className="certi-desc">Congratulations! you have reached to the next
                                            level of the course.</div>
                                    <div className="progress">
                                        <div className="progress-bar bg-warning progressbar-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="video-complete-by">6/10</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="d-sm-flex align-items-center justify-content-between mb-0">
                        <h1 className="h3 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>Courses</h1>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h4 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>
                                    In Progress
                                </h1>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="align-items-center mb-4">
                                <h1 className="h4 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>
                                    All Courses
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-1 col-lg-1"></div>
                <div className="col-xl-10 col-lg-10">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="h4 m-0 font-weight-bold text-secondary">Email Marketing<br />
                                        <span className="small" style={{ fontSize: '14px' }}><b>Duration</b> : 2 months</span>
                                    </h6>
                                    <button type="button" className="btn btn-warning start-btn">Continue</button>
                                </div>
                                <div className="card-body text-drak">
                                    {/* <h5 className="card-title">Success card title</h5> */}
                                    <p className="course-desc">Email marketing is the act of sending a commercial message.</p>
                                    <p className="course-img-p"><img className="course-img" src="/assets/images/12.png" alt="" width="85" height="85" /></p>
                                    <div className="clear-both"></div>
                                </div>
                                <div className="progress-line-bar">
                                    <div className="progress">
                                        <div className="progress-bar bg-warning" style={{ "width": "60%" }}></div>
                                    </div><br />
                                </div>
                                <div className="card-footer bg-transparent border-gray">
                                    <h6 className="h6 m-0 font-weight-bold text-secondary">Courses Details :</h6>
                                    <div className="row">
                                        <div className="col-md-4 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 10 Lessons</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 30 Videos</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="h4 m-0 font-weight-bold text-secondary">Email Marketing<br />
                                        <span className="small" style={{ fontSize: '14px' }}><b>Duration</b> : 2 months</span>
                                    </h6>
                                    <button type="button" className="btn btn-warning start-btn">Continue</button>
                                </div>
                                <div className="card-body text-drak">
                                    {/* <h5 className="card-title">Success card title</h5> */}
                                    <p className="course-desc">Email marketing is the act of sending a commercial message.</p>
                                    <p className="course-img-p"><img className="course-img" src="/assets/images/12.png" alt="" width="85" height="85" /></p>
                                    <div className="clear-both"></div>
                                </div>
                                <div className="progress-line-bar">
                                    <div className="progress">
                                        <div className="progress-bar bg-warning" style={{ "width": "60%" }}></div>
                                    </div><br />
                                </div>
                                <div className="card-footer bg-transparent border-gray">
                                    <h6 className="h6 m-0 font-weight-bold text-secondary">Courses Details :</h6>
                                    <div className="row">
                                        <div className="col-md-4 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 10 Lessons</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 30 Videos</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="h4 m-0 font-weight-bold text-secondary">Email Marketing<br />
                                        <span className="small" style={{ fontSize: '14px' }}><b>Duration</b> : 2 months</span>
                                    </h6>
                                    <button type="button" className="btn btn-warning start-btn">Continue</button>
                                </div>
                                <div className="card-body text-drak">
                                    {/* <h5 className="card-title">Success card title</h5> */}
                                    <p className="course-desc">Email marketing is the act of sending a commercial message.</p>
                                    <p className="course-img-p"><img className="course-img" src="/assets/images/12.png" alt="" width="85" height="85" /></p>
                                    <div className="clear-both"></div>
                                </div>
                                <div className="progress-line-bar">
                                    <div className="progress">
                                        <div className="progress-bar bg-warning" style={{ "width": "60%" }}></div>
                                    </div><br />
                                </div>
                                <div className="card-footer bg-transparent border-gray">
                                    <h6 className="h6 m-0 font-weight-bold text-secondary">Courses Details :</h6>
                                    <div className="row">
                                        <div className="col-md-4 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 10 Lessons</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 30 Videos</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
