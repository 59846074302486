import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

export default function QuizChild(props) {
    // console.log(props.quizData);
    let tutorialSteps = [
        {
            label: props.quizData.question,
            option1: props.quizData.option_1,
            option2: props.quizData.option_2,
            option3: props.quizData.option_3,
            option4: props.quizData.option_4,
        },
    ];

    if (props.quizData.length) {
        tutorialSteps = props.quizData;
    }
    
    const useStyles = makeStyles(theme => ({
        root: {
            maxWidth: 1200,
            flexGrow: 1,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            height: 70,
            paddingLeft: theme.spacing(4),
            backgroundColor: theme.palette.background.default,
        },
    }));

    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => { setActiveStep(prevActiveStep => prevActiveStep + 1); };

    const handleBack = () => { setActiveStep(prevActiveStep => prevActiveStep - 1); };

    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Quiz</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11 mt-4">
                    <div className={classes.root}>
                        <Paper square elevation={0} className={classes.header}>
                            <Typography>{tutorialSteps[activeStep].id}: {tutorialSteps[activeStep].question}</Typography>
                        </Paper>
                        <Paper square elevation={0} className={classes.header}>
                            <Typography><input type="radio" name={`options${tutorialSteps[activeStep].id}`} id={tutorialSteps[activeStep].id} value={tutorialSteps[activeStep].option_1} />
                                &nbsp; {tutorialSteps[activeStep].option_1}
                            </Typography>
                        </Paper>
                        <Paper square elevation={0} className={classes.header}>
                            <Typography><input type="radio" name={`options${tutorialSteps[activeStep].id}`} id={tutorialSteps[activeStep].id} value={tutorialSteps[activeStep].option_2} />
                                &nbsp; {tutorialSteps[activeStep].option_2}
                            </Typography>
                        </Paper>
                        <Paper square elevation={0} className={classes.header}>
                            <Typography><input type="radio" name={`options${tutorialSteps[activeStep].id}`} id={tutorialSteps[activeStep].id} value={tutorialSteps[activeStep].option_3} />
                                &nbsp; {tutorialSteps[activeStep].option_3}
                            </Typography>
                        </Paper>
                        <Paper square elevation={0} className={classes.header}>
                            <Typography><input type="radio" name={`options${tutorialSteps[activeStep].id}`} id={tutorialSteps[activeStep].id} value={tutorialSteps[activeStep].option_4} />
                                &nbsp; {tutorialSteps[activeStep].option_4}
                            </Typography>
                        </Paper>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            variant="text"
                            activeStep={activeStep}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}> Next
                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} Prev
                                </Button>
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
