import React from 'react'
import { useParams } from "react-router-dom";
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import AllChapterChild from '../../components/teamleaderchild/AllChapterChild';

function AllChapter() {
    let { id } = useParams();
    return (
        <>
            <div id="wrapper">
                <SideNavBar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <UserData />
                        <div className="container-fluid">
                            <AllChapterChild id={id} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllChapter;
