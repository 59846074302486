import React from 'react';
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import CoursesChild from '../../components/courseschild/CoursesChild';

export default function Courses() {

    return (
        <>
            <div id="wrapper">
                <SideNavBar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <UserData/>
                        <div className="container-fluid">
                            <CoursesChild/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
