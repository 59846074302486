import React, { browserHistory } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';
import JwtExpiryToken from './services/JwtExpiryToken';
import Protected from './services/Protected';
import Login from './pages/login/Login';
import Logout from './services/Logout';
import Dashboard from "./pages/dashboard/Dashboard";
import Courses from './pages/courses/Courses';
import StartCourse from './pages/courses/StartCourse';
import CourseChapter from './pages/courses/CourseChapter';
// import StartOptionalCourse from './pages/courses/StartOptionalCourse';
// import OptionalSkills from './pages/tutorials/optionalskills/OptionalSkills';
// import StartSoftCourse from './pages/courses/StartSoftCourse';
// import SoftSkills from './pages/tutorials/softskills/SoftSkills';
// import StartTechCourse from './pages/courses/StartTechCourse';
// import TechnicalSkills from './pages/tutorials/technicalskills/TechnicalSkills';
import SkillsCourse from './pages/courses/SkillsCourse';
import SkillsTutorial from './pages/tutorials/SkillsTutorial';
import Sessions from './pages/sessions/Sessions';
import CreateSession from './pages/sessions/CreateSession';
import Help from './pages/help/Help';
import Profile from './pages/profile/Profile';
import TeamLeader from './pages/teamleader/TeamLeader';
import CreateCourse from './pages/teamleader/CreateCourse';
import CreateLesson from './pages/teamleader/CreateLesson';
import CreateChapter from './pages/teamleader/CreateChapter';
import UpdateCourse from './pages/teamleader/UpdateCourse';
import UpdateLesson from './pages/teamleader/UpdateLesson';
import UpdateChapter from './pages/teamleader/UpdateChapter';
import LessonData from './pages/teamleader/LessonData';
import ChapterData from './pages/teamleader/ChapterData';
import AllLesson from './pages/teamleader/AllLesson';
import AllChapter from './pages/teamleader/AllChapter';

import Quiz from './pages/quiz/Quiz';
import CreateTeamLeader from './pages/teamleader/CreateTeamLeader';
import ReactTab from './components/courseschild/ReactTab';
import AddLesson from './pages/teamleader/AddLesson';
import AddChapter from './pages/teamleader/AddChapter';


export default function App() {
    return (
        <>
            <Router history={browserHistory}>
                <Route path="/" exact>
                    <Redirect to="/login" />
                </Route>
                <JwtExpiryToken />
                <Route path="/login/" exact component={Login} />
                <Route path="/logout/" exact component={Logout} />
                <Protected path="/dashboard/" exact component={Dashboard} />
                <Protected path="/courses/" exact component={Courses} />
                <Protected path="/courses/startcourse/" exact component={StartCourse} />
                {/* <Protected path="/courses/skills" exact component={StartOptionalCourse} />
                <Protected path="/courses/skills/tutorials" exact component={OptionalSkills} />
                <Protected path="/courses/skills" exact component={StartSoftCourse} />
                <Protected path="/courses/skills/tutorials" exact component={SoftSkills} />
                <Protected path="/courses/skills" exact component={StartTechCourse} />
                <Protected path="/courses/skills/tutorials" exact component={TechnicalSkills} /> */}
                <Protected path="/courses/skills/:id/" exact component={SkillsCourse} />
                <Protected path="/skills/chapter/:id" exact component={CourseChapter} />
                <Protected path="/courses/skills/tutorials/:id/" exact component={SkillsTutorial} />
                <Protected path="/sessions/" exact component={Sessions} />
                <Protected path="/sessions/createsession" exact component={CreateSession} />
                <Protected path="/help" exact component={Help} />
                <Protected path="/profile" exact component={Profile} />
                <Protected path="/teamlead/" exact component={TeamLeader} />
                <Protected path="/teamlead/createcourse/" exact component={CreateCourse} />
                <Protected path="/teamlead/createlesson" exact component={CreateLesson} />
                <Protected path="/teamlead/createchapter" exact component={CreateChapter} />
                <Protected path="/teamlead/updatecourse/:id" exact component={UpdateCourse} />
                <Protected path="/teamlead/updatelesson/:id" exact component={UpdateLesson} />
                <Protected path="/teamlead/addlesson/:id" exact component={AddLesson} />
                <Protected path="/teamlead/addchapter/:id" exact component={AddChapter} />
                <Protected path="/teamlead/updatechapter/:id" exact component={UpdateChapter} />
                <Protected path="/teamlead/lessondata/:id" exact component={LessonData} />
                <Protected path="/teamlead/chapterdata/:id" exact component={ChapterData} />
                <Protected path="/teamlead/lessons/:id" exact component={AllLesson}/>
                <Protected path="/teamlead/chapters/:id" exact component={AllChapter}/>

                <Protected path="/quiz" exact component={Quiz} />
                {/* <Protected path="/teamlead/createcourse" exact component={CreateTeamLeader} /> */}
                {/* <Route path="/youtube" exact component={Youtube} /> */}
                {/* <ReactTab /> */}
            </Router>

        </>
    )
}
