import React, { useState, useEffect } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import Axios from 'axios';

function CreateLessonChild(props) {

    var id=props.id;
    

    const [course, setCourse] = useState([])
    const [loading, setLoading] = useState(false)
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];
    let baseUrl = 'http://lms.rightfullabs.com/apis/';

   

        useEffect(() => {
            Axios.get(baseUrl + 'AdminPanel/CourseData',
                {
                    method: 'GET',
                    headers: {
                        "Authorization": `LMS-oauthtoken ${jwt}`,
                        "Content-Type": "application/json",
                        "Apiuserid": "2020010157",
                        "Scope": "LMS.Rightfullabs.READ"
                    },
                }
            )
                .then(res => {
                    
                    setCourse(res.data)
                    //     setPosts(res.data);
                    //     setLoading(false);
                    //     if (res.data == '') {
                    //         setErrorAlert({ type: "warning", message: "Data not found!" });
                    //     } else {
                    //         setErrorAlert({ type: "success", message: "Data load successfully!" });
                    //     }
                })
                .catch(err => {
                    console.log(err);
                })
        }, [])


    

    function addLesson() {
        var last_id = document.getElementById("course_id").value;
        var lesson_number = document.getElementById("lesson_number").value;
        var lesson_title = document.getElementById("lesson_title").value;
        var lesson_desc = document.getElementById("lesson_desc").value;
        var data = {
            last_id: last_id,
            lesson_number: lesson_number,
            lesson_title: lesson_title,
            lesson_desc: lesson_desc,
        }
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `LMS-oauthtoken ${jwt}`,
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Apiuserid": "2020010157",
                "Scope": "LMS.Rightfullabs.INSERT"
            },
        }
        Axios.post(baseUrl + 'AdminPanel/LessonData', data, options)
            .then(res => {
              setLoading(true)
            })
            .catch(err => {
                console.log(err);
            })
    }
    
    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Course :&nbsp;
                            {
                                course.map((x) => {
                                    return (
                                        <>
                                            {x.course_title}
                                            <input type="hidden" id="course_id" value={`${x.course_id}`} />
                                        </>
                                    )
                                })
                            }
                        </h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 col-md-12">
                    <div className="container-fluid" style={{ backgroundColor: 'white', padding: '10px 50px', borderRadius: '25px' }}>
                       
                            <h2 className="course-title" style={{ paddingTop: '25px' }}>Lesson Information</h2>
                            <div className="row" style={{ paddingTop: '25px' }}>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Lesson Number</label>
                                        <input type="text" className="form-control" id="lesson_number" name="lesson_number" placeholder=" Lesson Number" autofocus="" />
                                    </div>
                                    <div className="form-group">
                                        <label>Lesson Title</label>
                                        <input type="text" className="form-control" id="lesson_title" name="lesson_title" placeholder=" Lesson Title" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label>Course Description</label>
                                    <div className="form-group" >
                                        <input type="text" className="form-control" style={{ height: "200px" }} id="lesson_desc" placeholder="Lesson Description" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">

                                    <NavLink to="/teamlead/createchapter">
                                            <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} onClick={addLesson}> Add Lesson</button>
                                        </NavLink>
                                        {/* {
                                            loading?
                                            <NavLink to="/teamlead/createchapter">
                                            <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} onClick={addLesson}> Add Lesson</button>
                                        </NavLink>:
                                       <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} onClick={addLesson}> Add Lesson</button>

                                        } */}
                                    </div>
                                </div>
                            </div>
                      
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateLessonChild;
