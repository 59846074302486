import React from 'react';
// import LearnerReport from './LearnerReport'
// import LearnerTable from './LearnerTable'

function AllLearnerChild() {
    return (
        <div>
            <div className="row">
            <div className="col-lg-9">
                    <div className="d-sm-flex  mb-3 align-items-center text-gray justify-content-between mb-4 ml-5" style={{ fontWeight: '700',fontSize:"35px",wordSpacing:"15px" }}>
                        Top Learner
                    </div>
            </div>
        </div>
        <div className="row">
        {/* <LearnerReport/>
        <LearnerReport/>
        <LearnerReport/>
        <LearnerReport/> */}
        </div>

        <div className="row">
            <div className="col-lg-9">
                    <div className="d-sm-flex  mb-3 align-items-center text-gray justify-content-between mb-4 ml-5" style={{ fontWeight: '700',fontSize:"35px",wordSpacing:"15px" }}>
                       Other Learner
                    </div>
            </div>
        </div>
        {/* <LearnerTable/> */}
        </div>
    )
}

export default AllLearnerChild
