import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

function UpdateChapterChild(props) {
    var id = props.id;

    const [chapterdata, setChapterData] = useState([]);
    const [fileName, setFileName] = useState(false);
    const [update, setUpdate] = useState(false);

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];
    let baseUrl = 'http://lms.rightfullabs.com/apis/';
    useEffect(() => {
        var data = { chapter_id: id }
        // console.log(data)
        Axios.post(baseUrl + 'AdminPanel/ChapterData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
                console.log(res);
                setChapterData(res.data);

                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    function fileData() {
        setFileName(true)
    }

    function updateChapter() {
        var chapter_id = document.getElementById("chapter_id").value;
        var chapter_number = document.getElementById("chapter_number").value;
        var chapter_title = document.getElementById("chapter_title").value;
        var file_path = document.querySelector('input[type="file"]').files[0];
        var formData = new FormData();

        formData.append("chapter_id", chapter_id);
        formData.append("chapter_number", chapter_number);
        formData.append("chapter_title", chapter_title);
        formData.append("file", file_path);

        const options = {
            method: 'POST',
            headers: {
                "Authorization": `LMS-oauthtoken ${jwt}`,
                // "Accept": "application/json",
                "Content-Type": "multipart/form-data",
                "Apiuserid": "2020010157",
                "Scope": "LMS.Rightfullabs.UPDATE"
            },
        }
        Axios.post(baseUrl + 'AdminPanel/ChapterData', formData, options)
            .then(res => {
                console.log(res);
                setUpdate(true);
            })
            .catch(err => {
                console.log(err);
            })
    }

    if (update) {
        return (<Redirect to={`/teamlead/chapters/${id}`} />);
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h3 className="h3 mb-3 text-gray" style={{ fontWeight: '900', fontSize: "30px" }}>Lesson :&nbsp;
                            {/* {
                                lesson.map((x) => {
                                    return (
                                        <>
                                            {x.lesson_title}
                                            <input type="hidden" id="lesson_id" value={`${x.lesson_id}`} />
                                        </>
                                    )
                                })
                            } */}
                        </h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 col-md-12">
                    <div className="container-fluid" style={{ backgroundColor: 'white', padding: '10px 50px', borderRadius: '25px' }}>
                        <h2 className="course-title" style={{ paddingTop: '25px' }}>Chapter Information</h2>
                        {
                            chapterdata.map(x => {
                                return (

                                    <>
                                        <div className="row" style={{ paddingTop: '25px' }}>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label>Chapter Number</label>
                                                    <input type="hidden" className="form-control" value={x.chapter_id} id="chapter_id" />
                                                    <input type="text" className="form-control" defaultValue={x.chapter_number} id="chapter_number" name="chapter_number" placeholder=" Chapter Number" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Chapter Title</label>
                                                    <input type="text" className="form-control" defaultValue={x.chapter_title} id="chapter_title" name="chapter_title" placeholder=" Chapter Title" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label>Add File</label>
                                                    <div className="upload-btn-wrapper upload-file">
                                                        <h6>{fileName ? fileName : x.file_path}</h6>
                                                        <button className="btn btn-primary btn-upload">Selected File</button>
                                                        <center><small>Video, PPT, DOCS &amp; PDF Files</small></center>

                                                        <input type="hidden" defaultValue={x.file_path} name="file_path" id="file_path" onChange={fileData} />

                                                        <input type="file" name="file_path" id="file_path" onChange={fileData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <button className="btn btn-warning btn-rounded" style={{ fontWeight: "700" }} onClick={updateChapter}> Update Chapter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateChapterChild;