import React, { useState, useEffect } from 'react'
import TopNavBar from './TopNavBar';

function UserData() {

    const [person, setPerson] = useState([]);
    useEffect(() => {
        var data = JSON.parse(localStorage.getItem('userData'));
        setPerson([data]);
    }, [])

    return (
        <div>
            {
                person.map((x) => {
                    return <TopNavBar person={x} />
                })
            }
        </div>
    )
}

export default UserData;