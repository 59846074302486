import React, { useState, useEffect } from 'react';
import Axios from 'axios'
import { NavLink } from 'react-router-dom';
// import PDFViewer from 'pdf-viewer-reactjs';

export default function SkillsCourseChild(props) {
    var id = props.id;

    const [lesson, setLesson] = useState([])
    const [course,setCourse] = useState([])

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    useEffect(() => {
        var data = { course_id: id }
       
        Axios.post(baseUrl + 'courses/AllCourseData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
            console.log(res)
               setCourse(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    useEffect(() => {
        var data = { course_id: id }
    
        Axios.post(baseUrl + 'courses/AllLessonData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
             
               setLesson(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    return (
        <>
           {
             course.map(x =>{
                 return(
                     <>
                      <div className="row p-4 border-rounded ">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11" key={x.course_id}>
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                 <h1 className="h2 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>Course :&nbsp; {x.course_title}</h1>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                        
                    </div>
                    <div className="row" style={{ textAlign: 'center' }}>
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <div className="col-md-6 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> {x.total_lessons} lesson</div>
                            <div className="col-md-6 fa-icon"><span className="fa  fa-text"></span></div>
                            <div className="col-md-6 fa-icon"><span className="fa fa-tasks fa-text"></span>{x.total_lessons}  files</div>
                        </div>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                         <div className="row" style={{fontWeight:"700"}}>
                            <h6 className="mt-2">Duration :</h6>  <div className=" ml-3 fa-icon" style={{marginBottom:"40px"}}><span className="fa fa-calendar fa-text">
                                </span>{x.course_duration} </div>
                         </div>
                        </div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                 <h1 className="h6 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>{x.course_desc}</h1>
                    </div>
                </div>
            </div>
                     </>
                 )
             })
           }
   
   <div className="row mt-4">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11" >
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                 <h1 className="h2 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Lessons</h1>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-1 col-lg-1"></div>
                <div className="col-xl-10 col-lg-10">
                    <div className="row">
{
    lesson.map(x =>{
        return(
            <>
                        
               
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 className="h4 m-0 font-weight-bold text-secondary" key={x.lesson_id}>{x.lesson_title}</h6>
                                    {/* <NavLink to={`/skills/chapter/${x.lesson_id}`}>
                                    <button type="button" className="btn btn-warning start-btn">Start</button>
                                    </NavLink> */}
                                </div>
                                <NavLink to={`/skills/chapter/${x.lesson_id}`}>
                                <div className="card-body text-drak">
                                   
                                        <video className="video-thumb" src={`http://lms.rightfullabs.com/apis/uploads/sintel_trailer.mp4`} type="video/mp4" />
                                    
                                </div>
                                </NavLink>

                                {/* <div className="card-footer bg-transparent border-gray">
                                    <div className="row" style={{ textAlign: 'center' }}>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 10 Lessons</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 30 Videos</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    
          
            </>
        )
    })
}
</div>
    
</div>
                </div>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h6 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>description</h1>
                    </div>
                </div>
            </div>
        </>
    )
}
