import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import AlertMsg from '../../services/AlertMsg';

const SessionChildPages = ({ posts, loading }) => {
    const [sessions, setSession] = useState([]);
    const [errorAlert, setErrorAlert] = useState('');

    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    function deleteSession(id) {

        var session_id = id;
        var data = { session_id: session_id }
        var id = JSON.stringify(data);
        // console.log(id);
        const heads = {
            method: 'POST',
            headers: {
                "Authorization": `LMS-oauthtoken ${jwt}`,
                "Content-Type": "application/json",
                "Apiuserid": "2020010157",
                "Scope": "LMS.Rightfullabs.DELETE"
            },
        }
        Axios.post(baseUrl + "sessions/getSessionsData/", id, heads)
            .then(res => {
                 console.log(res.data)
                if (res.data.code == 200 || res.data.code == 400 || res.data.code == 401) {
                    // alert(res.data.message);
                    setErrorAlert({ type: "success", message: res.data.message });
                    document.getElementById(session_id).remove();
                    // setStatus(true);
                }
            })
            .catch(error => {
                // console.log(error) 
                setErrorAlert({ type: "error", message: 'Something went wrong. Please try again!' });
            });
    }

    const snackbarClose = () => {
        setErrorAlert(false);
    };

    if (loading) {
        return <h2>loading...</h2>;
    }
    return (
        <>
            {/* <ul className="list-group mb-4">
                {
                    posts.map(post => (
                        <li key={post.session_id} className="list-group-item">
                            {post.title}
                        </li>
                    ))
                }
            </ul> */}
            {
                errorAlert &&
                <AlertMsg type={errorAlert.type} message={errorAlert.message} open={errorAlert} close={snackbarClose} autoclose={3000} />
            }
            {/* {
                posts.map(post => (
                    <li key={post.session_id} className="list-group-item">
                        {post.title}
                    </li>
                ))
            } */}
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800"></h1>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>Sessions</h1>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-3 text-gray-800" style={{ fontWeight: 'bold' }}>
                            <NavLink to="/sessions/createsession">
                                {data.teamLeader === 1 || data.userId === 127 || data.userId === 209 ? <button type="button" className="btn btn-warning session-btn">Create Session</button> : null}
                            </NavLink>
                        </h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10">
                    <div className="container-fluid">
                        {/* <div className="scroll-session"> */}
                        <div className="row">
                            {posts.map(post => (
                                <div id={post.session_id} key={post.session_id} className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                                            <h3 className="card-title myCardTitle">{post.session_title}</h3>
                                                        </div>
                                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                                            <div className="row">
                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                    {data.teamLeader === 1 || data.userId === 127 || data.userId === 209 ? <span className="fa fa-pencil-square-o" style={{ cursor: 'pointer', fontSize: '26px', color: '#ff4500', position: 'relative', right: '15px', top: '2px' }}></span> : null}
                                                                </div>
                                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                    {data.teamLeader === 1 || data.userId === 127 || data.userId === 209 ? <span className="fa fa-trash-o" style={{ cursor: 'pointer', fontSize: '24px', color: '#ff4500', position: 'relative', right: '15px' }} data-toggle="modal" data-target={`#myModal${post.session_id}`}></span> : null}
                                                                </div>
                                                                <div className="modal fade" id={`myModal${post.session_id}`}>
                                                                    <div className="modal-dialog modal-sm" style={{ margin: '1.75rem auto' }}>
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h4 className="modal-title">Are you sure?</h4>
                                                                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                            </div>
                                                                            <div className="modal-body">{post.title}</div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => deleteSession(post.session_id)}>Confirm</button>
                                                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <h6 className="card-title myCardTitle">{post.title}</h6>
                                                        </div>
                                                    </div>
                                                    {/* <div className="btn btn-success new-btn">New</div> */}
                                                    <div className="clear-fix"></div>
                                                    <video className="video-thumb" src={baseUrl + `uploads/${post.file_path}`} type="video/mp4" data-toggle="modal" data-target={`#exampleModalCenter${post.session_id}`} style={{ cursor: 'pointer' }} />
                                                    <div className="modal fade" id={`exampleModalCenter${post.session_id}`} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ margin: '1.75rem auto' }}>
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLongTitle">{post.title}</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span data-dismiss="modal" aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <video className="video-thumb" src={baseUrl + `uploads/${post.file_path}`} type="video/mp4" controls />
                                                                    {/* <Player
                                                                            playsInline
                                                                            poster="/assets/poster.png"
                                                                            src={`http://lms.rightfullabs.com/apis/uploads/${post.file_path}`}
                                                                        /> */}
                                                                    {/* <iframe title="vimeo-player" src="https://player.vimeo.com/video/396113790" width="640" height="360" frameborder="0" allowfullscreen></iframe> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-text myCardVenue">{post.day} On {post.time}<br />{post.present_by}</div>
                                                    <div className="card-text myCardBy">By: {post.host_by}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default SessionChildPages;