import React from 'react';
import { NavLink } from 'react-router-dom';
// import PDFViewer from 'pdf-viewer-reactjs';

export default function StartCoursesChild(props) {

    var id=props.id;
   

    return (
        <>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                        <h1 className="h2 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>Lessons</h1>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                        <h1 className="h3 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>sub title</h1>
                    </div>
                    <div className="row" style={{ textAlign: 'center' }}>
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <div className="col-md-6 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 3 chapters</div>
                            <div className="col-md-6 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 1 Video</div>
                            <div className="col-md-6 fa-icon"><span className="fa fa-tasks fa-text"></span> 2 files</div>
                        </div>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h6 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>description</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div className="col-xl-1 col-lg-1"></div>
                <div class="col-xl-10 col-lg-10">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="h4 m-0 font-weight-bold text-secondary">Email Marketing</h6>
                                    {/* <button type="button" className="btn btn-warning start-btn">Start</button> */}
                                </div>
                                <div class="card-body text-drak">
                                    <NavLink to="/courses/tutorial">
                                        <video className="video-thumb" src={`http://lms.rightfullabs.com/apis/uploads/sintel_trailer.mp4`} type="video/mp4" />
                                    </NavLink>
                                </div>
                                <div class="card-footer bg-transparent border-gray">
                                    <div className="row" style={{ textAlign: 'center' }}>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 10 Lessons</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 30 Videos</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-xl-4 col-lg-6">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="h4 m-0 font-weight-bold text-secondary">Email Marketing</h6>
                                </div>
                                <div class="card-body text-drak">
                                    <PDFViewer
                                        document={{
                                            url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf'
                                        }}
                                    />
                                </div>
                                <div class="card-footer bg-transparent border-gray">
                                    <div className="row" style={{ textAlign: 'center' }}>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 10 Lessons</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 30 Videos</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="h4 m-0 font-weight-bold text-secondary">Email Marketing</h6>
                                </div>
                                <div class="card-body text-drak">
                                    <PDFViewer
                                        document={{
                                            url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf'
                                        }}
                                    />
                                </div>
                                <div class="card-footer bg-transparent border-gray">
                                    <div className="row" style={{ textAlign: 'center' }}>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 10 Lessons</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 30 Videos</div>
                                        <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h6 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>description</h1>
                    </div>
                </div>
            </div>
        </>
    )
}
