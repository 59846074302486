import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import CourseChapterData from './CourseChapterData';

export default function CoursesCardChild(props) {
    console.log(props.courses)
    var id = props.courses.course_id;
    console.log(id)
    const [course, setCourse] = useState([])
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';

    function courseData(id) {
        var data = { course_id: id }
        console.log(data)
        Axios.post(baseUrl + 'profile/ProfileData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.UPDATE"
                },
            }
        )
            .then(res => {

                console.log(res)

                //    setLesson(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })

    }

    useEffect(() => {
        var data = { course_id: id }
        console.log(data)
        Axios.post(baseUrl + 'courses/AllCourseData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
                console.log(res)
                setCourse(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <>
            {
                props.courses.course_title != null
                    ?
                    <div id={props.courses.courses_id} key={props.courses.courses_id} class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="h4 m-0 font-weight-bold text-secondary">{props.courses.course_title}<br />
                                    <span className="small" style={{ fontSize: '14px' }}><b>Duration</b> : {props.courses.course_duration}</span>
                                </h6>
                                <NavLink to={`/courses/skills/${props.courses.course_id}`}>
                                    <button type="button" className="btn btn-warning start-btn" onClick={() => { courseData(props.courses.course_id) }}>Start</button>
                                </NavLink>
                            </div>

                            <div class="card-body text-drak">
                                {/* <h5 class="card-title">Success card title</h5> */}
                                <p className="course-desc">{props.courses.course_desc}</p>
                                <p className="course-img-p"><img className="course-img" src={`http://lms.rightfullabs.com/images/${props.courses.file_path}`} alt="" width="85" height="85" /></p>
                                <div className="clear-both"></div>
                            </div>
                            <div className="progress-line-bar">
                                <div className="progress">
                                    <div className="progress-bar bg-warning" style={{ "width": "60%" }}></div>
                                </div><br />
                            </div>
                            {/* {
                                course.map(x => {
                                    return ( */}
                                        {/* <> */}
                                            <div class="card-footer bg-transparent border-gray">
                                                <h6 class="h6 mb-3-0 font-weight-bold text-secondary">Courses Details :</h6>
                                                <div className="row">
                                                    <div className="col-md-6 fa-icon"><span className="fa fa-newspaper-o fa-text"></span>{props.courses.total_lessons} Lessons</div>
                                                    <div className="col-md-6 fa-icon"><span className="fa fa-tasks  fa-text"></span>{props.courses.total_lessons} files</div>
                                                    {/* <div className="col-md-4 fa-icon"><span className="fa fa-tasks fa-text"></span> 6/10</div> */}
                                                </div>
                                            </div>
                                        {/* </>
                                    )
                                })
                            } */}
                        </div>
                    </div>
                    :
                    <div className="col-lg-12" style={{ textAlign: "center" }}><h3 style={{ color: "red" }}>No Data Found.</h3></div>
            }
        </>
    )
}
