import React from 'react';
import '../sessions/sessions.css';
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import QuizData from '../../components/quizChild/QuizData';

export default function Quiz() {
    return (
        <>
            <div id="wrapper">
                <SideNavBar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <UserData />
                        <div className="container-fluid">
                            <QuizData />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
