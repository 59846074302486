import React, { useState, useEffect } from 'react';
import Axios from 'axios'
import { NavLink } from 'react-router-dom';
// import PDFViewer from 'pdf-viewer-reactjs';

export default function CourseChapterData(props) {
 var id=props.id;


 const [lesson, setLesson] = useState([])
 const [chapter, setChapter] = useState([])
    var data = JSON.parse(localStorage.getItem('userData'));
    var jwt = data['jwt'];

    let baseUrl = 'http://lms.rightfullabs.com/apis/';

   
    useEffect(() => {
        var data = { lesson_id: id }

        Axios.post(baseUrl + 'AdminPanel/LessonData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
          console.log(res)
               setLesson(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    // courses/AllChapterDataByID
    // courses/AllChapterDataNext
    // courses/AllChapterDataPrev

    useEffect(() => {
        var data = { lesson_id: id }
      
        Axios.post(baseUrl + 'courses/AllChapterData', data,
            {
                method: 'POST',
                headers: {
                    "Authorization": `LMS-oauthtoken ${jwt}`,
                    "Content-Type": "application/json",
                    "Apiuserid": "2020010157",
                    "Scope": "LMS.Rightfullabs.READ"
                },
            }
        )
            .then(res => {
              
               setChapter(res.data)
                //     setSession(res.data);
                //     setPosts(res.data);
                // setLoading(false);
                // if (res.data == '') {
                //     setErrorAlert({ type: "warning", message: "Data not found!" });
                // } else {
                //     setErrorAlert({ type: "success", message: "Data load successfully!" });
                // }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <>
          {
              lesson.map(x =>{
                  return(
                      <>
                        <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* {/* <h1 className="h3 mb-3 text-gray-800"></h1>  */}
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                  <h6 className=" mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>Lesson {x.lesson_number}</h6>
                    </div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                  <h2 className="h2 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>{x.lesson_title}</h2>
                    </div>
                    
                    {/* <div className="row" style={{ textAlign: 'center' }}>
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <div className="col-md-6 fa-icon"><span className="fa fa-newspaper-o fa-text"></span> 3 chapters</div>
                            <div className="col-md-6 fa-icon"><span className="fa fa-youtube-play fa-text"></span> 1 Video</div>
                            <div className="col-md-6 fa-icon"><span className="fa fa-tasks fa-text"></span> 2 files</div>
                        </div>
                    </div> */}
                             <br/>
                             <br/>
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h4 className="h6 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>{x.lesson_desc}</h4>
                    </div>
                </div>
            </div>
                      </>
                  )
              })
          }
 
            <div className="row mt-4">
                <div className="col-xl-1 col-lg-1"></div>
                <div className="col-xl-10 col-lg-10">
                    <div className="row">
{
    chapter.map(x=>{
        return(
            <>
           
           <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
           <NavLink to={`/courses/skills/tutorials/${x.chapter_id}`}>
                        <div className="card shadow mb-4">
               <div className="card-body text-drak">
               <div className="row mb-3">
                       <div className="col-lg-11">
                <h6>Chapter {x.chapter_number} </h6>
                       </div>
                       {/* <div className="col-lg-1 ">
                       <i class="fa fa-ellipsis-v text-gray"></i>
                       </div> */}
                   </div>
              <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-4 offset-lg-1 offset-md-1">
                   <img src="/assets/images/download.jpg" height="80"  />
              </div>
              <div className="col-lg-5 col-md-5 col-sm-4 offset-lg-1 offset-md-1 ">
        <p className="" style={{fontWeight:"700",fontSize:"18px"}}>{x.chapter_title}</p>
                {/* <div className="row">
                       <div className="col-md-6 fa-icon" style={{fontSize:"8px",fontWeight:"700"}}><span className="fa fa-newspaper-o"></span> video</div>
                       <div className="col-md-6 fa-icon" style={{fontSize:"8px",fontWeight:"700"}}><span className="fa fa-youtube-play"></span> Videos</div>
       
                   </div> */}
              </div>
              </div>
               </div>
              
           </div>
           </NavLink>
   
                        </div>
                        
          
            </>
        )
    })
}
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-lg-1">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {/* <h1 className="h3 mb-3 text-gray-800"></h1> */}
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h6 mb-0 text-gray-800" style={{ fontWeight: 'bold' }}>description</h1>
                    </div>
                </div>
            </div>
        </>
    )
}
