import React from 'react';
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import CreateCourseChild from '../../components/teamleaderchild/CreateCourseChild';

function CreateCourse() {
    return (
        <div>
             <div id="wrapper">
                <SideNavBar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <UserData />
                        <div className="container-fluid">
                           <CreateCourseChild/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateCourse;
