import React from 'react';
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import TeamLeaderChild from '../../components/teamleaderchild/TeamLeaderChild';

export default function TeamLeader() {
    return (
        <>
            <div id="wrapper">
                <SideNavBar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <UserData/>
                        <div className="container-fluid">
                            <TeamLeaderChild/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
