import React from 'react';
import SideNavBar from '../../components/headers/sidenavbar/SideNavBar';
import UserData from '../../components/headers/topnavbar/UserData';
import ProfileData from '../../components/profilechild/ProfileData';

export default function Profile() {
    return (
        <>
            <div id="wrapper">
                <SideNavBar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <UserData />
                        <div className="container-fluid">
                            <ProfileData />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
