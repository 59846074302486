import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import '../login/login.css';
import PostData from '../../services/PostData';
import AlertMsg from '../../services/AlertMsg';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            snackbarmsg: '',
            snackbaropen: false,
            isAuthenticated: false,
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    login() {
        PostData('login', 'Login', this.state).then((result) => {
            let auth = result;
            if (auth.code == 400 || auth.code == 401 || auth.code == 402) {
                this.setState({ type: 'error', snackbaropen: true, snackbarmsg: auth.message });
            } else {
                if (auth.userEmail) {   // && !auth.userId != 27
                    localStorage.setItem('userData', JSON.stringify(auth));
                    this.setState({ isAuthenticated: true });
                    // this.setState({ type: 'success', snackbaropen: true, snackbarmsg: "Login Successfully!" });
                } else {
                    this.setState({ type: 'error', snackbaropen: true, snackbarmsg: "Please try again. Something went wrong!" });
                    // this.setState({ type: 'warning', snackbaropen: true, snackbarmsg: "You are not authorized user to access this website!" });
                    this.setState({ isAuthenticated: false });
                }
            }
        });
    }

    snackbarClose = (e) => {
        this.setState({
            snackbaropen: false
        })
    };

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        if (this.state.isAuthenticated == true || localStorage.getItem('userData')) {
            return (<Redirect to={'/dashboard'} />);
        } else {
            return (
                <>
                    {
                        this.state.snackbarmsg &&
                        <AlertMsg type={this.state.type} message={this.state.snackbarmsg} open={this.state.snackbaropen} close={this.snackbarClose} autoclose={3000} />
                    }
                    <div className="bg-gradient-bg-img">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 col-lg-12 col-md-9">
                                    <div className="card border-0 shadow-lg my-8" style={{ backgroundColor: 'transparent', borderRadius: '0px' }}>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-lg-6 d-none d-lg-block container-height">
                                                    <h1 className="lms-txt-title">LMS</h1>
                                                    <h4 className="lms-title">Title For LMS</h4>
                                                </div>
                                                <div className="col-lg-6" style={{ backgroundColor: 'white', height: '700px' }}>
                                                    <div className="p-5">
                                                        <br />
                                                        <div className="text-center">
                                                            <img src="/assets/images/user.png" alt="" height="150" width="150" />
                                                        </div>
                                                        <div className="text-center">
                                                            <h1 className="h4 text-gray-900 mb-4">&nbsp;</h1>
                                                        </div>
                                                        <form className="user">
                                                            <div className="form-group">
                                                                <input type="email" className="form-control form-control-user" id="email" name="email" placeholder="Enter Your Email-Id" autofocus="" onChange={this.onChange} style={{ textAlign: 'center', backgroundColor: '#f3f5f9' }} />
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="password" className="form-control form-control-user" id="password" name="password" placeholder="Enter Your Password" onChange={this.onChange} style={{ textAlign: 'center', backgroundColor: '#f3f5f9' }} />
                                                            </div>
                                                            <div className="text-center">
                                                                <input type="button" value="Sign In" className="btn btn-warning btn-user btn-block" style={{ width: '50%', position: 'relative', margin: '0% 0% 0 26%' }} onClick={this.login} />
                                                            </div>
                                                        </form>
                                                        <div className="text-center rfspl-logo">
                                                            <img src="/assets/images/rfspl-logo.png" alt="" height="45" width="190" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}
